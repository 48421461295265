import React from "react";
import Modal from "@material-ui/core/Modal";
import "./Home.css";
import parse from "html-react-parser";

const FooterModal = (props) => {
  const { dataFooter, titleFooter, openFooterModal, handleCloseFooterModal } =
    props;
  return (
    <>
      <Modal open={openFooterModal} onClose={handleCloseFooterModal}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                onClick={handleCloseFooterModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="row modalcontent1">
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  {parse(`<h3>${titleFooter}</h3><hr/>`)}
                  <div
                    style={{
                      textAlign: "left",
                      height: "350px",
                      overflowY: "auto",
                    }}
                  >
                    <div>{parse(`${dataFooter}`)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FooterModal;

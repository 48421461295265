import React, { Component } from "react";
import "./games.css";
import parse from "html-react-parser";
import { $ } from "react-jquery-plugin";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { base_url } from "../../constants";
import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export class Cards32 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_stake_put_106: 0,
      total_stake_put_107: 0,
      total_stake_put_108: 0,
      total_stake_put_109: 0,
      total_stake_put_110: 0,
      total_stake_put_111: 0,
      total_stake_put_112: 0,
      total_stake_put_113: 0,
      total_stake_put_114: 0,
      total_stake_put_115: 0,
      total_stake_put_116: 0,
      total_stake_put_117: 0,
      total_stake_put_118: 0,
      total_stake_put_119: 0,
      total_stake_put_120: 0,
      total_stake_put_121: 0,
      total_stake_put_122: 0,
      total_stake_put_123: 0,
      total_stake_put_124: 0,
      stakes: [
        [
          ["stake1", 0],
          ["stake2", 0],
          ["stake3", 0],
          ["stake4", 0],
          ["stake5", 0],
          ["stake6", 0],
          ["stake7", 0],
          ["stake8", 0],
        ],
      ],
      ruleModal: false,
      rules: "",
      balance: { points: "0", exposure: "0" },
      basePath: "",
      mands: [],
      roundId: "",
      timer: 0,
      roundMessage: "",
      betTimer: 0,
      p8: "",
      p9: "",
      p10: "",
      p11: "",
      result: "",
      cardInterval: "",
      roundTimer: "",
      exactTimmer: "",
    };

    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  openRuleModal = () => {
    this.setState({ ruleModal: true });
  };

  closeRuleModal = () => {
    this.setState({ ruleModal: false });
  };

  hideorshowbetpanel(panelname) {
    var currentvalue = $("#" + panelname).css("display");
    if (currentvalue === "none") {
      $("#" + panelname).css("display", "block");
    } else {
      $("#" + panelname).css("display", "none");
    }
  }

  changestakevalue(valueofstake, idnum, selection_odds) {
    $("#stakevalueforselction" + idnum).val(valueofstake);
    let profit_value = valueofstake * selection_odds - valueofstake;
    $("#p" + idnum).css("color", "green");
    $("#p" + idnum).html("P = " + profit_value);
  }

  betInputFieldChange(e, idnum, selection_odds) {
    let profit_value = e.target.value * selection_odds - e.target.value;
    $("#p" + idnum).css("color", "green");
    $("#p" + idnum).html("P = " + profit_value);
  }

  placeBet(marketId, selectionId, stakes, selection_odds) {
    if (parseInt(stakes) < 100) {
      alert("Minimum of 100 stake points are required to place a bet.");
    } else if (parseInt(this.state.balance.points) < parseInt(stakes)) {
      alert("You do not have enough stake points to place bet.");
    } else {
      // Changing the value of P
      var c_stake = this.state['total_stake_put_' + selectionId];
      c_stake += parseInt(stakes);
      this.state['total_stake_put_' + selectionId] = parseInt(c_stake);
      let profit_value = c_stake * selection_odds - c_stake;
      $("#p" + selectionId).css("color", "green");
      $("#p" + selectionId).html("P = " + profit_value);
      // Changing the value of P
      let betObj = {
        uid: JSON.parse(localStorage.getItem("token")).uid,
        gid: this.props.location.pathname.split("/")[3],
        mid: marketId,
        sid: selectionId,
        roundid: this.state.roundId,
        stake: parseInt(stakes),
      };

      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(betObj),
      };

      fetch(base_url + "/G32cards/insertbetinfo", requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          // console.log(json);
        })
        .catch((err) => {
          console.log(err);
        });

      let obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
      const refreshOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };
      setTimeout(() => {
        fetch(base_url + "/welcome/refreshBalance", refreshOptions)
          .then((res) => res.json())
          .then((json) => {
            this.setState({ balance: json.data[0] });
          })
          .catch((err) => console.log(err));
      }, 1000);
    }
  }

  showStakes() {
    const v = document.getElementById("set_pop").style.display;

    if (v === "none" || v === "") {
      document.getElementById("set_pop").style.display = "block";
    } else {
      document.getElementById("set_pop").style.display = "none";
    }
  }

  handleChangeStake = (e) => {
    const { name, value } = e.target;
    let idx = Number(name);
    let arr = [...this.state.stakes];
    arr[idx] = [...arr[idx]];
    arr[idx][1] = value;
    this.setState({ stakes: arr });
  };

  onSave = () => {
    const obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
    for (let i = 0; i < this.state.stakes.length; i++) {
      let keys = this.state.stakes[i][0];
      let value = this.state.stakes[i][1];
      obj[keys] = value;
    }

    const requestOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/welcome/updateStakes", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        let arr = [];
        for (let keys in json.data[0]) {
          arr.push([keys, json.data[0][keys]]);
        }
        this.setState({ stakes: arr });
        localStorage.setItem("stakes", JSON.stringify(json.data));
        document.getElementById("set_pop").style.display = "none";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  startTimer() {
    if (this.state.exactTimmer > 0) {
      this.state.roundTimer = setInterval(this.countDown, 1000);
    }
  }

  latestRound() {
    const roundOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
    };

    fetch(base_url + "/G32cards/getlatestround", roundOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.status === 200 && json.data.timmer !== "-10") {
          this.setState({
            roundId: json.data.roundid,
            betTimer: json.data.timmer > 12 ? json.data.timmer - 12 : 0,
            exactTimmer: json.data.timmer,
            p8: json.data.p8_img_name,
            p9: json.data.p9_img_name,
            p10: json.data.p10_img_name,
            p11: json.data.p11_img_name,
            result: json.data.result,
          });
        }
      });
  }

  countDown() {
    // Remove one second, set state so a re-render happens
    if (this.state.exactTimmer <= 42 && this.state.exactTimmer >= 12) {
      this.setState({ betTimer: this.state.betTimer - 1 });
    } else if (this.state.exactTimmer < 12 && this.state.exactTimmer >= 9) {
      this.setState({ roundMessage: "Bet Window is closed. Good Luck!" });
      if (this.state.result === "") {
        this.latestRound();
      }
    } else if (this.state.exactTimmer < 9 && this.state.exactTimmer >= 5) {
      this.setState({ roundMessage: "Drawing cards..." });
      if (this.state.result === "") {
        this.latestRound();
      }
    } else if (this.state.exactTimmer < 5 && this.state.exactTimmer >= 3) {
      this.setState({ roundMessage: "Computing result." });
      if (this.state.result === "") {
        this.latestRound();
      }
    } else if (this.state.exactTimmer < 3 && this.state.exactTimmer >= 0) {
      this.state.total_stake_put_106 = 0;
      this.state.total_stake_put_107 = 0;
      this.state.total_stake_put_108 = 0;
      this.state.total_stake_put_109 = 0;
      this.state.total_stake_put_110 = 0;
      this.state.total_stake_put_111 = 0;
      this.state.total_stake_put_112 = 0;
      this.state.total_stake_put_113 = 0;
      this.state.total_stake_put_114 = 0;
      this.state.total_stake_put_115 = 0;
      this.state.total_stake_put_116 = 0;
      this.state.total_stake_put_117 = 0;
      this.state.total_stake_put_118 = 0;
      this.state.total_stake_put_119 = 0;
      this.state.total_stake_put_120 = 0;
      this.state.total_stake_put_121 = 0;
      this.state.total_stake_put_122 = 0;
      this.state.total_stake_put_123 = 0;
      this.state.total_stake_put_124 = 0;
      this.setState({ roundMessage: "Updating Points and Exposure." });
      if (this.state.result === "") {
        this.latestRound();
      }
    } else if (this.state.exactTimmer < 0) {
      clearInterval(this.state.roundTimer);
      window.location.reload();
    }

    this.setState({ exactTimmer: this.state.exactTimmer - 1 });
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      let obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };

      fetch(base_url + "/welcome/getStakeList", requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          let arr = [];
          for (let keys in json.data[0]) {
            arr.push([keys, json.data[0][keys]]);
          }
          this.setState({ stakes: arr });
          localStorage.setItem("stakes", JSON.stringify(json.data));
        })
        .catch((err) => {
          console.log(err);
        });

      fetch(base_url + "/welcome/refreshBalance", requestOptions)
        .then((res) => res.json())
        .then((json) => {
          this.setState({ balance: json.data[0] });
        })
        .catch((err) => console.log(err));

      const roundOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
      };

      fetch(base_url + "/G32cards/getlatestround", roundOptions)
        .then((res) => res.json())
        .then((json) => {
          if (json.status === 200 && json.data.timmer !== "-10") {
            this.setState({
              roundId: json.data.roundid,
              betTimer: json.data.timmer > 12 ? json.data.timmer - 12 : 0,
              exactTimmer: json.data.timmer,
              p8: json.data.p8_img_name,
              p9: json.data.p9_img_name,
              p10: json.data.p10_img_name,
              p11: json.data.p11_img_name,
              result: json.data.result,
            });
            this.startTimer();
          } else {
            this.setState({
              roundMessage:
                "Game is suspended for now. Please come back later to play 32 Cards.",
            });
          }
        })
        .catch((err) => console.log(err));

      const options = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify({
          moduleid: this.props.location.pathname.split("/")[3],
        }),
      };

      fetch(base_url + "/welcome/getmarketandselections", options)
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            basePath: json.base_path,
            rules: json.data.rules,
            mands: json.data.mands,
          });
        })
        .catch((err) => console.log(err));
    }
  }

  render() {
    return (
      <div className="gamesmain">
        <div className="row gameheadrow">
          <div className="col-md-2 col-sm-12"></div>
          <div className="col-md-8 col-sm-12">
            <div className="row">
              <div className="col col-md-1 col-sm-1">
                <ul className="tab-menu" style={{ display: "inline" }}>
                  <li className="ver-menu settingstack">
                    <Link to="/" className="active">
                      <i
                        className="fa fa-home"
                        style={{ fontSize: "16px" }}
                      ></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col col-md-1 col-sm-1">
                <ul className="tab-menu" style={{ display: "inline" }}>
                  <li
                    className="ver-menu settingstack"
                    onClick={this.showStakes}
                  >
                    <h6 className="active">
                      <i className="fa fa-cog" style={{ fontSize: "16px" }}></i>
                    </h6>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-10">
                <p style={{ color: "#fff" }}>
                  <span className="fa fa-user orangecolor"></span>{" "}
                  {JSON.parse(localStorage.getItem("token")).email}
                </p>
              </div>
              <div className="col col-md-3 col-sm-6">
                <p className="orangecolor">
                  Balance{" "}
                  <span style={{ color: "#fff" }}>
                    {this.state.balance.points}
                  </span>
                </p>
              </div>
              <div className="col col-md-3 col-sm-6">
                <p className="orangecolor">
                  Exposure{" "}
                  <span style={{ color: "#fff" }}>
                    ({this.state.balance.exposure})
                  </span>
                </p>
              </div>
              <div className="slip_set-pop" id="set_pop">
                <div className="overlay right-side">
                  <div className="side-wrap setting-wrap" id="settingSlide">
                    <div className="side-content" id="coinList">
                      <h3 className="stakeheading">Stake</h3>
                      <dl
                        className="setting-block stake-setting"
                        id="editCustomizeStakeList"
                      >
                        {this.state.stakes.map((stake, idx) => {
                          return (
                            <dd key={stake[0]}>
                              <input
                                name={idx}
                                type="text"
                                value={stake[1]}
                                onChange={this.handleChangeStake}
                              />
                            </dd>
                          );
                        })}
                        <dd className="col-stake_edit">
                          <h6
                            className="btn-send ui-link"
                            id="save"
                            onClick={this.onSave}
                          >
                            Save
                          </h6>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-12"></div>
        </div>
        <div className="row">
          <div className="col-md-2 col-sm-12"></div>
          <div className="col col-md-8 gamearea">
            <div className="row">
              <h6
                className="rules"
                style={{ width: "auto" }}
                onClick={this.openRuleModal}
              >
                RULES <i className="fa fa-info-circle"></i>
              </h6>
              <div className="roundid">
                Round :{" "}
                {this.state.roundId ? this.state.roundId : "No Round Available"}
              </div>
              <div className="col col-md-12 col-sm-12 cardarea">
                <div className="row">
                  <div
                    className="col-md-4 col-sm-12 mastercard"
                    id="mastercard"
                  >
                    {this.state.exactTimmer < 5 &&
                    this.state.exactTimmer !== "" ? (
                      <div className="winner32">
                        <div>Winner</div>
                        <div>Player {this.state.result.slice(1)}</div>
                      </div>
                    ) : (
                      <span className="timmerab" id="timmer">
                        {this.state.betTimer}
                      </span>
                    )}
                  </div>
                  <div
                    className="col-md-8 col-sm-12 ab_card_array"
                    id="ab_card_array"
                  >
                    <div className="row a_card_array c32" id="a_card_array">
                      <div className="col col-md-3 col-sm-3">
                        <img
                          alt=""
                          src={
                            this.state.exactTimmer < 9 &&
                            this.state.exactTimmer !== ""
                              ? "/assets/images/cards/" + this.state.p8
                              : "/assets/images/card-back.jpg"
                          }
                          className="g32_card"
                          id="club1"
                        />
                        <h3 className="plushead">+</h3>
                        <h3 className="addamount">8</h3>
                      </div>
                      <div className="col col-md-3 col-sm-3">
                        <img
                          alt=""
                          src={
                            this.state.exactTimmer < 8 &&
                            this.state.exactTimmer !== ""
                              ? "/assets/images/cards/" + this.state.p9
                              : "/assets/images/card-back.jpg"
                          }
                          className="g32_card"
                          id="club1"
                        />
                        <h3 className="plushead">+</h3>
                        <h3 className="addamount">9</h3>
                      </div>
                      <div className="col col-md-3 col-sm-3">
                        <img
                          alt=""
                          src={
                            this.state.exactTimmer < 7 &&
                            this.state.exactTimmer !== ""
                              ? "/assets/images/cards/" + this.state.p10
                              : "/assets/images/card-back.jpg"
                          }
                          className="g32_card"
                          id="club1"
                        />
                        <h3 className="plushead">+</h3>
                        <h3 className="addamount">10</h3>
                      </div>
                      <div className="col col-md-3 col-sm-3">
                        <img
                          alt=""
                          src={
                            this.state.exactTimmer < 6 &&
                            this.state.exactTimmer !== ""
                              ? "/assets/images/cards/" + this.state.p11
                              : "/assets/images/card-back.jpg"
                          }
                          className="g32_card"
                          id="club1"
                        />
                        <h3 className="plushead">+</h3>
                        <h3 className="addamount">11</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.roundMessage ? (
          <div className="row">
            <div className="col col-md-2 col-sm-12"></div>
            <div
              className="col col-md-8 col-12"
              style={{
                backgroundColor: "#fff",
                padding: "100px 15px",
                marginTop: "5px",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              {this.state.roundMessage}
            </div>
          </div>
        ) : (
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-md-2 col-sm-12"></div>
            <div className="col col-md-8 marketarea">
              <div className="accordion acccustomstyle" id="marketaccordion">
                {this.state.mands
                  ? Object.keys(this.state.mands).map((key) => {
                      return (
                        <div
                          className="card"
                          key={this.state.mands[key][0].mid}
                        >
                          <div
                            className="card-header"
                            id={"heading" + this.state.mands[key][0].mid}
                          >
                            <h2 className="mb-0">
                              <button
                                className="btn btn-link btn-block text-left marketbtn"
                                type="button"
                                data-toggle="collapse"
                                data-target={
                                  "#collapse" + this.state.mands[key][0].mid
                                }
                                aria-expanded="true"
                                aria-controls={
                                  "#collapse" + this.state.mands[key][0].mid
                                }
                              >
                                {key}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={"collapse" + this.state.mands[key][0].mid}
                            className="collapse show"
                            aria-labelledby={
                              "heading" + this.state.mands[key][0].mid
                            }
                            data-parent="#marketaccordion"
                          >
                            <div className="card-body">
                              {this.state.mands[key].map((selection) => {
                                return (
                                  <div
                                    className="marketrow"
                                    key={selection.sid}
                                  >
                                    <div className="row">
                                      <div className="col col-md-7">
                                        {selection.selectionimg ? (
                                          <img
                                            src={
                                              this.state.basePath +
                                              selection.selectionimg
                                            }
                                            alt=""
                                            className="selectionimg"
                                            height="80px"
                                            width="auto"
                                          />
                                        ) : (
                                          <b>{selection.selectionname}</b>
                                        )}

                                        <p
                                          className="profitorloss"
                                          id={"p" + selection.sid}
                                        >
                                          -- 0.00
                                        </p>
                                      </div>
                                      <div className="col col-md-5">
                                        <div
                                          className="betbox"
                                          onClick={() =>
                                            this.hideorshowbetpanel(
                                              "m" + selection.sid
                                            )
                                          }
                                        >
                                          <span className="intotimes">
                                            {selection.selection_odds} <br /> 1M
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="optionsforbetplacing"
                                      id={"m" + selection.sid}
                                    >
                                      <div className="row">
                                        <div className="col col-md-2 col-sm-6">
                                          <button
                                            className="cancelbtn"
                                            onClick={() =>
                                              this.hideorshowbetpanel(
                                                "m" + selection.sid
                                              )
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                        <div className="col col-md-2 col-sm-6">
                                          <span className="intotimesdown">
                                            {selection.selection_odds}
                                          </span>
                                        </div>
                                        <div className="col col-md-4 col-sm-6">
                                          <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            className="betinputfield"
                                            id={
                                              "stakevalueforselction" +
                                              selection.sid
                                            }
                                            // onChange={(e) =>
                                            //   this.betInputFieldChange(
                                            //     e,
                                            //     selection.sid,
                                            //     selection.selection_odds
                                            //   )
                                            // }
                                          />
                                        </div>
                                        <div className="col col-md-3 col-sm-6">
                                          <button
                                            className="placebetbtn"
                                            onClick={() => {
                                              this.placeBet(
                                                this.state.mands[key][0].mid,
                                                selection.sid,
                                                $(
                                                  "#stakevalueforselction" +
                                                    selection.sid
                                                ).val(),
                                                selection.selection_odds
                                              );
                                              this.hideorshowbetpanel(
                                                "m" + selection.sid
                                              );
                                            }}
                                          >
                                            Place Bet
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row stakes">
                                        {this.state.stakes.map((stake) => {
                                          return (
                                            <div
                                              className="col col-md-1 col-sm-4"
                                              key={stake[0]}
                                            >
                                              <button
                                                name="stack_value"
                                                className="stakeinput"
                                                onClick={() =>
                                                  this.changestakevalue(
                                                    stake[1],
                                                    selection.sid,
                                                    selection.selection_odds
                                                  )
                                                }
                                              >
                                                {stake[1]}
                                              </button>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        )}

        <Modal open={this.state.ruleModal} onClose={this.closeRuleModal}>
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                onClick={this.closeRuleModal}
                style={{ textAlign: "right" }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div
                className="row modalcontent1"
                style={{ overflowY: "hidden" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <h3>Rules</h3>
                  <hr />
                  <div
                    style={{
                      textAlign: "left",
                      height: "350px",
                      overflowY: "auto",
                    }}
                  >
                    <div>{parse(`${this.state.rules}`)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Cards32;

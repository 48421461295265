import React, { useState } from "react";
import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import "./Home.css";
import {base_url} from "../constants"
import Modal from "@material-ui/core/Modal";
import Navbar from "./Navbar";
import FooterModal from "./FooterModal";
import SidebarLeft from "./SidebarLeft";
import SidebarRight from "./SidebarRight";

export default function Home(props) {
  const styles =
    '.formmodalbloack {background-image: url("/assets/images/modalimg.jpg")}' +
    '@media only screen and (max-width: 900px) {.modal-body {background-image: url("/assets/images/modalimg.jpg")}';

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);
  const [openFooterModal, setFooterModal] = useState(false);
  const [dataFooter, setDataFooter] = useState();
  const [titleFooter, setTitleFooter] = useState();
  const login = localStorage.getItem("token");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { gameList, basicInfo, setLoginValue } = props;
  const [error, setError] = useState("");

  //----------------------------------------------------------------------------------------

  const formValidate = () => {
    if (email === "") {
      setError("Enter a valid Email-Id.");
      return false;
    } else if (password === "") {
      setError("Enter a valid Password.");
      return false;
    }
    return true;
  };

  const login_req = (e) => {
    if (formValidate()) {
      let obj = { email: email, password: password };
      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };

      e.preventDefault();
      fetch(base_url + "/welcome/userLogin", requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json.status === 200) {
            localStorage.setItem("token", JSON.stringify(json.data[0]));
            setModalLogin(true);
            handleCloseLoginModal();
            setError("");
            setEmail("");
            setPassword("");
          } else {
            setError(json.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleOpenLoginModal = () => {
    setOpenLoginModal(true);
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  const handleOpenFooterModal = (event) => {
    setFooterModal(true);
    setTitleFooter(event.target.dataset.user);
    if (basicInfo) {
      if (event.target.dataset.name === basicInfo[6].infoname) {
        setDataFooter(basicInfo[6].infodata);
      }
      if (event.target.dataset.name === basicInfo[7].infoname) {
        setDataFooter(basicInfo[7].infodata);
      }
      if (event.target.dataset.name === basicInfo[8].infoname) {
        setDataFooter(basicInfo[8].infodata);
      }
      if (event.target.dataset.name === basicInfo[9].infoname) {
        setDataFooter(basicInfo[9].infodata);
      }
      if (event.target.dataset.name === basicInfo[10].infoname) {
        setDataFooter(basicInfo[10].infodata);
      }
    }
  };

  const handleCloseFooterModal = () => {
    setFooterModal(false);
  };

  const handleChange = (e) => {
    if (e.target.name === "emailid") {
      setEmail(e.target.value);
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  return (
    <div className="home" style={{backgroundColor:"#ededed"}}>
      <style>{styles}</style>
      <Navbar
        handleOpenLoginModal={handleOpenLoginModal}
        modalLogin={modalLogin}
        gameList={gameList}
        setLoginValue={setLoginValue}
      />
      <div className="row mainbody">
        <SidebarLeft
          handleOpenLoginModal={handleOpenLoginModal}
          gameList={gameList}
        />

        <div
          className={`col-md-6 col-sm-12 midbar ${
            login ? "" : "extrastylemidbar"
          }`}
        >
          <Carousel fade>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="assets/images/banner1.png"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="assets/images/banner2.png"
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="assets/images/banner3.png"
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="assets/images/banner4.png"
                alt="Fourth slide"
              />
            </Carousel.Item>
          </Carousel>

          <div className="bottomdata">
            <p style={{ marginTop: "130px" }}>
              <img
                src="assets/images/whatsapp.svg"
                width="20px"
                alt="Whatsapp"
              />{" "}
              Support :
              {basicInfo.length !== 0 ? (
                <a
                  href={
                    "https://api.whatsapp.com/send?phone=+91 " +
                    basicInfo[5].infodata
                  }
                  style={{
                    color: "#A5A5A5",
                    textDecoration: "none",
                    cursor: "pointer",
                    target: "_blank",
                  }}
                >
                  +91 {basicInfo[5].infodata}
                </a>
              ) : (
                ""
              )}
            </p>
            <ul className="tab-menu">
              <li
                className="ver-menu-mid"
                style={{ borderLeft: "1px solid #797777" }}
              >
                <h6
                  onClick={handleOpenFooterModal}
                  data-user="Privacy Policy"
                  data-name="privacypolicy"
                >
                  Privacy Policy
                </h6>
              </li>
              <li className="ver-menu-mid">
                <h6
                  onClick={handleOpenFooterModal}
                  data-user="KYC"
                  data-name="kyc"
                >
                  KYC
                </h6>
              </li>
              <li className="ver-menu-mid">
                <h6
                  onClick={handleOpenFooterModal}
                  data-user="Terms & Conditions"
                  data-name="termsandcondition"
                >
                  Terms & Conditions
                </h6>
              </li>
              <li className="ver-menu-mid">
                <h6
                  onClick={handleOpenFooterModal}
                  data-user="Rules & Regulations"
                  data-name="rulesregulation"
                >
                  Rules & Regulations
                </h6>
              </li>
              <li className="ver-menu-mid">
                <h6
                  onClick={handleOpenFooterModal}
                  data-user="Responsible Gambling"
                  data-name="responsiblegambling"
                >
                  Responsible Gambling
                </h6>
              </li>
            </ul>
          </div>
        </div>

        <SidebarRight basicInfo={basicInfo} />
      </div>

      <Modal
        className="loginModal"
        open={openLoginModal}
        onClose={handleCloseLoginModal}
      >
        <div className="modal-body modal-content modal-dialog modal">
          <button
            type="button"
            className="close"
            onClick={handleCloseLoginModal}
            style={{ position: "absolute", right: "5px", zIndex: "9999" }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="row modalcontent">
            <div className="col-md-6 col-sm-12 formmodalbloack"></div>
            <div className="col-md-6 col-sm-12 modalform">
              <img
                className="modallogo"
                src="assets/images/logosm.jpg"
                alt=""
              />
              <form className="form">
                <input
                  className="form-control mr-sm-2 umail"
                  type="email"
                  name="emailid"
                  placeholder="Email-Id"
                  aria-label="Email-Id"
                  onChange={handleChange}
                />
                <input
                  className="form-control mr-sm-2 upass"
                  type="password"
                  name="password"
                  placeholder="Password"
                  aria-label="Password"
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="btn btn-orange pull-right mr-2"
                  onClick={login_req}
                >
                  Login <span className="fa fa-sign-in-alt"></span>
                </button>
                {error ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "70px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                      fontSize: "15px",
                    }}
                  >
                    <p style={{ color: "red" }}>{error}</p>
                  </div>
                ) : (
                  <div style={{ display: "none" }}></div>
                )}
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <FooterModal
        dataFooter={dataFooter}
        titleFooter={titleFooter}
        openFooterModal={openFooterModal}
        handleCloseFooterModal={handleCloseFooterModal}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { base_url } from "../constants";

const SidebarRight = (props) => {
  const { basicInfo } = props;
  const [list, setList] = useState([]);

  useEffect(() => {
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
    };

    fetch(base_url + "/welcome/newsList", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.status === 200) {
          setList(json.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="col-md-3 col-sm-12 sidebar_right">
        <nav className="sidebar-nav">
          <div className="top-head-sport">
            <h6
              className="path-back"
              style={{ cursor: "default", paddingTop: "2px" }}
            >
              <i aria-hidden="true" className="fa fa-ellipsis-v"></i>
            </h6>
            <ul className="text-right mr-2" style={{ listStyle: "none" }}>
              <li>
                <h6
                  style={{
                    cursor: "default",
                    paddingTop: "5px",
                    fontWeight: "450",
                    color: "white",
                  }}
                >
                  NEWS
                </h6>
              </li>
            </ul>
          </div>
          <ul className="navul_news">
            {basicInfo.length !== 0 ? (
              <li className="nav-item">
                Current point rate is : {basicInfo[11].infodata} points.
              </li>
            ) : (
              ""
            )}

            {list
              ? list.map((news) => {
                  return <li key={news.id}>{news.newsdata}</li>;
                })
              : ""}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default SidebarRight;

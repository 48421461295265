import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const SidebarLeft = (props) => {
  const { handleOpenLoginModal, gameList } = props;
  const [login, setLogin] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, [token]);

  return (
    <>
      <div className="col-md-3 col-sm-12 sidebar">
        <nav className="sidebar-nav">
          <div className="top-head-sport">
            <h6
              className="path-back"
              style={{ cursor: "default", paddingTop: "2px" }}
            >
              <i aria-hidden="true" className="fa fa-ellipsis-v"></i>
            </h6>
            <ul className="text-right mr-2" style={{ listStyle: "none" }}>
              <li>
                <h6
                  style={{
                    cursor: "default",
                    paddingTop: "5px",
                    fontWeight: "450",
                    color: "#ffffff",
                  }}
                >
                  SPORTS
                </h6>
              </li>
            </ul>
          </div>

          <ul className="navul">
            {gameList && gameList.data
              ? gameList.data.map((data) => {
                  return (
                    <li className="nav-item" key={data.tid}>
                      {login ? (
                        <Link
                          to={"/rooms/" + data.tid}
                          className="nav-link nav-dropdown-toggle"
                        >
                          <img
                            src={gameList.base_path + data.typeimg}
                            className="gameimage"
                            alt=""
                          />
                          {data.typename}
                        </Link>
                      ) : (
                        <h6
                          className="nav-link nav-dropdown-toggle"
                          onClick={handleOpenLoginModal}
                          style={{ fontWeight: 400 }}
                        >
                          <img
                            src={gameList.base_path + data.typeimg}
                            className="gameimage"
                            alt=""
                          />
                          {data.typename}
                        </h6>
                      )}
                    </li>
                  );
                })
              : ""}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default SidebarLeft;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {base_url} from "../constants"

const Rooms = () => {
  const userData = JSON.parse(localStorage.getItem("token"));
  const [balance, setBalance] = useState(0);
  const [roomData, setRoomData] = useState();
  const [basePath, setBasePath] = useState();
  const { roomId } = useParams();

  useEffect(()=>{
    if (localStorage.getItem("token")) {
      const obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };
      fetch(base_url + "/welcome/refreshBalance", requestOptions)
        .then((res) => res.json())
        .then((json) => {
          setBalance(json.data[0]);
        })
        .catch((err) => console.log(err));
    }
  },[])
  
  
  useEffect(() => {
    const obj = { tid: roomId };
    const requestOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/welcome/getGameRooms", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setRoomData(json.data);
        setBasePath(json.base_path);
      })
      .catch((err) => console.log(err));
  }, [roomId]);

  return (
    <div className="gamesmain">
      <div className="row headrow">
        <div className="col-md-8 col-sm-12">
          <div className="row">
            <div className="col-md-2 col-sm-2">
              <Link to="/">
                <span className="fa fa-home orangecolor"></span>
              </Link>
            </div>
            <div className="col-md-4 col-sm-10">
              <p style={{ color: "#fff" }}>
                <span className="fa fa-user orangecolor"></span>{" "}
                {userData.email}
              </p>
            </div>
            <div className="col col-md-3 col-sm-6">
              <p className="orangecolor">
                Balance <span style={{ color: "#fff" }}>{balance.points}</span>
              </p>
            </div>
            <div className="col col-md-3 col-sm-6">
              <p className="orangecolor">
                Exposure{" "}
                <span style={{ color: "#fff" }}>({balance.exposure})</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12"></div>
      </div>
      <div className="row datarow">
        {roomData
          ? roomData.map((room) => {
              return (
                <div
                  className="col col-md-4 col-sm-12"
                  style={{ marginTop: "10px" }}
                  key={room.rid}
                >
                  <div
                    className="roombox"
                    style={{
                      backgroundImage: `url(${basePath + room.roomimg})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <h3>{room.roomtitle}</h3>
                    <Link
                      to={"/games/" + room.rid}
                      type="button"
                      className="btn roombtn"
                    >
                      {room.roomname}
                    </Link>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default Rooms;

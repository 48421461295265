import React, { Component } from "react";
import "./games.css";
import { $ } from "react-jquery-plugin";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import parse from "html-react-parser";
import { base_url } from "../../constants";

export default class Lucky7 extends Component {
  roundIntervalId = 0;
  totalTimer = 0;
  constructor(props) {
    super(props);
    this.state = {
      total_stake_put_1: 0,
      total_stake_put_2: 0,
      total_stake_put_3: 0,
      total_stake_put_4: 0,
      total_stake_put_5: 0,
      total_stake_put_6: 0,
      total_stake_put_7: 0,
      total_stake_put_8: 0,
      total_stake_put_9: 0,
      total_stake_put_10: 0,
      total_stake_put_11: 0,
      total_stake_put_12: 0,
      total_stake_put_13: 0,
      total_stake_put_14: 0,
      total_stake_put_15: 0,
      total_stake_put_16: 0,
      total_stake_put_17: 0,
      total_stake_put_18: 0,
      total_stake_put_19: 0,
      total_stake_put_20: 0,
      total_stake_put_21: 0,
      total_stake_put_22: 0,
      total_stake_put_23: 0,
      stakes: [
        [
          ["stake1", 0],
          ["stake2", 0],
          ["stake3", 0],
          ["stake4", 0],
          ["stake5", 0],
          ["stake6", 0],
          ["stake7", 0],
          ["stake8", 0],
        ],
      ],
      ruleModal: false,
      rules: "",
      balance: { points: "0", exposure: "0" },
      basePath: "",
      mands: [],
      roundId: "",
      timer: 0,
      roundMessage: "",
      cardResult: "",
      card: "/assets/images/cards/card-back.jpg",
    };
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.apiCalls = this.apiCalls.bind(this);
    this.result = this.result.bind(this);
  }

  openRuleModal = () => {
    this.setState({ ruleModal: true });
  };

  closeRuleModal = () => {
    this.setState({ ruleModal: false });
  };

  hideorshowbetpanel(panelname) {
    var currentvalue = $("#" + panelname).css("display");
    if (currentvalue === "none") {
      $("#" + panelname).css("display", "block");
    } else {
      $("#" + panelname).css("display", "none");
    }
  }

  changestakevalue(valueofstake, idnum, selection_odds) {
    $("#stakevalueforselction" + idnum).val(valueofstake);
    let profit_value = valueofstake * selection_odds - valueofstake;
    $("#p" + idnum).css("color", "green");
    $("#p" + idnum).html("P = " + profit_value);
  }

  // betInputFieldChange(e, idnum, selection_odds) {
  //   let profit_value = e.target.value * selection_odds - e.target.value;
  //   $("#p" + idnum).css("color", "green");
  //   $("#p" + idnum).html("P = " + profit_value);
  // }

  placeBet(marketId, selectionId, stakes, selection_odds) {
    if (parseInt(stakes) < 100) {
      alert("Minimum of 100 stake points are required to place a bet.");
    } else if (parseInt(this.state.balance.points) < parseInt(stakes)) {
      alert("You do not have enough stake points to place bet.");
    } else {
      // Changing the value of P
      var c_stake = this.state['total_stake_put_' + selectionId];
      c_stake += parseInt(stakes);
      this.state['total_stake_put_' + selectionId] = parseInt(c_stake);
      let profit_value = c_stake * selection_odds - c_stake;
      $("#p" + selectionId).css("color", "green");
      $("#p" + selectionId).html("P = " + profit_value);
      // Changing the value of P
      let betObj = {
        uid: JSON.parse(localStorage.getItem("token")).uid,
        gid: this.props.location.pathname.split("/")[3],
        mid: marketId,
        sid: selectionId,
        roundid: this.state.roundId,
        stake: parseInt(stakes),
      };

      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(betObj),
      };

      fetch(base_url + "/Lucky7/insertbetinfo", requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          // console.log(json);
        })
        .catch((err) => {
          console.log(err);
        });

      let obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
      const refreshOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };
      setTimeout(() => {
        fetch(base_url + "/welcome/refreshBalance", refreshOptions)
          .then((res) => res.json())
          .then((json) => {
            this.setState({ balance: json.data[0] });
          })
          .catch((err) => console.log(err));
      }, 1000);
    }
  }

  showStakes() {
    const v = document.getElementById("set_pop").style.display;

    if (v === "none" || v === "") {
      document.getElementById("set_pop").style.display = "block";
    } else {
      document.getElementById("set_pop").style.display = "none";
    }
  }

  handleChangeStake = (e) => {
    const { name, value } = e.target;
    let idx = Number(name);
    let arr = [...this.state.stakes];
    arr[idx] = [...arr[idx]];
    arr[idx][1] = value;
    this.setState({ stakes: arr });
  };

  onSave = () => {
    const obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
    for (let i = 0; i < this.state.stakes.length; i++) {
      let keys = this.state.stakes[i][0];
      let value = this.state.stakes[i][1];
      obj[keys] = value;
    }

    const requestOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/welcome/updateStakes", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        let arr = [];
        for (let keys in json.data[0]) {
          arr.push([keys, json.data[0][keys]]);
        }
        this.setState({ stakes: arr });
        localStorage.setItem("stakes", JSON.stringify(json.data));
        document.getElementById("set_pop").style.display = "none";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  apiCalls() {
    this.setState({
      roundMessage: "",
      card: "/assets/images/cards/card-back.jpg",
    });
    let obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
    const requestOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/welcome/refreshBalance", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ balance: json.data[0] });
      })
      .catch((err) => console.log(err));

    const roundOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
    };

    this.setState({ cardResult: "" });

    fetch(base_url + "/Lucky7/getlatestround", roundOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.status === 200) {
          this.setState({
            roundId: json.data.roundid,
            timer: parseInt(json.data.timmer),
            betTimer:
              parseInt(json.data.timmer - 30) < 0
                ? 0
                : parseInt(json.data.timmer - 30),
          });
          this.startTimer();
        } else {
          this.setState({ roundMessage: json.message });
        }
      })
      .catch((err) => console.log(err));
  }

  startTimer() {
    if (this.state.betTimer > 0) {
      this.roundTimer = setInterval(this.countDown, 1000);
    } else {
      this.totalTimer = setInterval(() => {
        this.state.timer = this.state.timer - 1;
        if (this.state.timer <= 0) {
          clearInterval(this.totalTimer);
          this.apiCalls();
        }
        if (this.state.timer === 10) {
          this.result();
        }
      }, 1000);

      if (this.state.timer <= 0) {
        this.setState({
          roundMessage:
            "Game is suspended for now. Please come back later to play Lucky7.",
        });
      } else if (this.state.betTimer <= 0) {
        this.setState({
          roundMessage:
            "Bet Window is closed. Please wait for the result. Good Luck!",
          card: "/assets/images/cards.gif",
        });
      }
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens
    this.setState({ betTimer: this.state.betTimer - 1 });
    this.state.timer = this.state.timer - 1;

    // Check if we're at zero.
    if (this.state.betTimer === 0) {
      this.setState({
        roundMessage:
          "Bet Window is closed. Please wait for the result. Good Luck!",
        card: "/assets/images/cards.gif",
      });
      clearInterval(this.roundTimer);
      setTimeout(this.result, 20000);
      setTimeout(this.apiCalls, 30000);
    }
  }

  result() {
    this.state.total_stake_put_1 = 0;
      this.state.total_stake_put_2 = 0;
      this.state.total_stake_put_3 = 0;
      this.state.total_stake_put_4 = 0;
      this.state.total_stake_put_5 = 0;
      this.state.total_stake_put_6 = 0;
      this.state.total_stake_put_7 = 0;
      this.state.total_stake_put_8 = 0;
      this.state.total_stake_put_9 = 0;
      this.state.total_stake_put_10 = 0;
      this.state.total_stake_put_11 = 0;
      this.state.total_stake_put_12 = 0;
      this.state.total_stake_put_13 = 0;
      this.state.total_stake_put_14 = 0;
      this.state.total_stake_put_15 = 0;
      this.state.total_stake_put_16 = 0;
      this.state.total_stake_put_17 = 0;
      this.state.total_stake_put_18 = 0;
      this.state.total_stake_put_19 = 0;
      this.state.total_stake_put_20 = 0;
      this.state.total_stake_put_21 = 0;
      this.state.total_stake_put_22 = 0;
      this.state.total_stake_put_23 = 0;
    this.setState({
      roundMessage:
        "Updating point and exposure. Please wait for the next game to start.",
    });

    let obj = { roundid: this.state.roundId };
    const requestOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/Lucky7/getresultofround", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => this.setState({ cardResult: json.data.imgname }))
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      let obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };

      fetch(base_url + "/welcome/getStakeList", requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          let arr = [];
          for (let keys in json.data[0]) {
            arr.push([keys, json.data[0][keys]]);
          }
          this.setState({ stakes: arr });
          localStorage.setItem("stakes", JSON.stringify(json.data));
        })
        .catch((err) => {
          console.log(err);
        });

      fetch(base_url + "/welcome/refreshBalance", requestOptions)
        .then((res) => res.json())
        .then((json) => {
          this.setState({ balance: json.data[0] });
        })
        .catch((err) => console.log(err));

      const roundOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
      };

      fetch(base_url + "/Lucky7/getlatestround", roundOptions)
        .then((res) => res.json())
        .then((json) => {
          if (json.status === 200) {
            this.setState({
              roundId: json.data.roundid,
              timer: json.data.timmer,
              betTimer: json.data.timmer - 30 < 0 ? 0 : json.data.timmer - 30,
            });
            this.startTimer();
          } else {
            this.setState({ roundMessage: json.message });
          }
        })
        .catch((err) => console.log(err));

      const options = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify({
          moduleid: this.props.location.pathname.split("/")[3],
        }),
      };

      fetch(base_url + "/welcome/getmarketandselections", options)
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            basePath: json.base_path,
            rules: json.data.rules,
            mands: json.data.mands,
          });
        })
        .catch((err) => console.log(err));
    }
  }

  componentWillUnmount() {
    clearInterval(this.roundIntervalId);
  }

  render() {
    return (
      <div className="gamesmain">
        <div className="row gameheadrow">
          <div className="col-md-2 col-sm-12"></div>
          <div className="col-md-8 col-sm-12">
            <div className="row">
              <div className="col col-md-1 col-sm-1">
                <ul className="tab-menu" style={{ display: "inline" }}>
                  <li className="ver-menu settingstack">
                    <Link to="/" className="active">
                      <i
                        className="fa fa-home"
                        style={{ fontSize: "16px" }}
                      ></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col col-md-1 col-sm-1">
                <ul className="tab-menu" style={{ display: "inline" }}>
                  <li
                    className="ver-menu settingstack"
                    onClick={this.showStakes}
                  >
                    <h6 className="active">
                      <i className="fa fa-cog" style={{ fontSize: "16px" }}></i>
                    </h6>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-10">
                <p style={{ color: "#fff" }}>
                  <span className="fa fa-user orangecolor"></span>{" "}
                  {JSON.parse(localStorage.getItem("token")).email}
                </p>
              </div>
              <div className="col col-md-3 col-sm-6">
                <p className="orangecolor">
                  Balance{" "}
                  <span style={{ color: "#fff" }}>
                    {this.state.balance.points}
                  </span>
                </p>
              </div>
              <div className="col col-md-3 col-sm-6">
                <p className="orangecolor">
                  Exposure{" "}
                  <span style={{ color: "#fff" }}>
                    ({this.state.balance.exposure})
                  </span>
                </p>
              </div>
              <div className="slip_set-pop" id="set_pop">
                <div className="overlay right-side">
                  <div className="side-wrap setting-wrap" id="settingSlide">
                    <div className="side-content" id="coinList">
                      <h3 className="stakeheading">Stake</h3>
                      <dl
                        className="setting-block stake-setting"
                        id="editCustomizeStakeList"
                      >
                        {this.state.stakes.map((stake, idx) => {
                          return (
                            <dd key={stake[0]}>
                              <input
                                name={idx}
                                type="text"
                                value={stake[1]}
                                onChange={this.handleChangeStake}
                              />
                            </dd>
                          );
                        })}
                        <dd className="col-stake_edit">
                          <h6
                            className="btn-send ui-link"
                            id="save"
                            onClick={this.onSave}
                          >
                            Save
                          </h6>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-12"></div>
        </div>
        <div className="row">
          <div className="col-md-2 col-sm-12"></div>
          <div className="col col-md-8 gamearea">
            <div className="row">
              <h6
                className="rules"
                style={{ width: "auto" }}
                onClick={this.openRuleModal}
              >
                RULES <i className="fa fa-info-circle"></i>
              </h6>
              <div className="roundid">
                Round :{" "}
                {this.state.roundId ? this.state.roundId : "No Round Available"}
              </div>
              <div className="col col-md-5 col-sm-12 timmerbox">
                <span className="timmer" id="timmer">
                  {this.state.betTimer}
                </span>
              </div>
              <div className="col col-md-7 col-sm-12 cardarea">
                {this.state.cardResult ? (
                  <div>
                    <h3 style={{ color: "#fff" }}>Result</h3>
                    <img
                      src={"/assets/images/cards/" + this.state.cardResult}
                      alt=""
                      className="cradgif"
                      style={{ width: "auto" }}
                    />
                  </div>
                ) : (
                  <img
                    src={this.state.card}
                    alt=""
                    className="cradgif"
                    style={{ width: "auto" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {this.state.roundMessage ? (
          <div className="row">
            <div className="col col-md-2 col-sm-12"></div>
            <div
              className="col col-md-8 col-12"
              style={{
                backgroundColor: "#fff",
                padding: "100px 15px",
                marginTop: "5px",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              {this.state.roundMessage}
            </div>
          </div>
        ) : (
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-md-2 col-sm-12"></div>
            <div className="col col-md-8 marketarea">
              <div className="accordion acccustomstyle" id="marketaccordion">
                {this.state.mands
                  ? Object.keys(this.state.mands).map((key) => {
                      return (
                        <div
                          className="card"
                          key={this.state.mands[key][0].mid}
                        >
                          <div
                            className="card-header"
                            id={"heading" + this.state.mands[key][0].mid}
                          >
                            <h2 className="mb-0">
                              <button
                                className="btn btn-link btn-block text-left marketbtn"
                                type="button"
                                data-toggle="collapse"
                                data-target={
                                  "#collapse" + this.state.mands[key][0].mid
                                }
                                aria-expanded="true"
                                aria-controls={
                                  "#collapse" + this.state.mands[key][0].mid
                                }
                              >
                                {key}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={"collapse" + this.state.mands[key][0].mid}
                            className="collapse show"
                            aria-labelledby={
                              "heading" + this.state.mands[key][0].mid
                            }
                            data-parent="#marketaccordion"
                          >
                            <div className="card-body">
                              {this.state.mands[key].map((selection) => {
                                return (
                                  <div
                                    className="marketrow"
                                    key={selection.sid}
                                  >
                                    <div className="row">
                                      <div className="col col-md-7">
                                        {selection.selectionimg ? (
                                          <img
                                            src={
                                              this.state.basePath +
                                              selection.selectionimg
                                            }
                                            alt=""
                                            className="selectionimg"
                                            height="80px"
                                            width="auto"
                                          />
                                        ) : (
                                          <b>{selection.selectionname}</b>
                                        )}

                                        <p
                                          className="profitorloss"
                                          id={"p" + selection.sid}
                                        >
                                          -- 0.00
                                        </p>
                                      </div>
                                      <div className="col col-md-5">
                                        <div
                                          className="betbox"
                                          onClick={() =>
                                            this.hideorshowbetpanel(
                                              "m" + selection.sid
                                            )
                                          }
                                        >
                                          <span className="intotimes">
                                            {selection.selection_odds} <br /> 1M
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="optionsforbetplacing"
                                      id={"m" + selection.sid}
                                    >
                                      <div className="row">
                                        <div className="col col-md-2 col-sm-6">
                                          <button
                                            className="cancelbtn"
                                            onClick={() =>
                                              this.hideorshowbetpanel(
                                                "m" + selection.sid
                                              )
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                        <div className="col col-md-2 col-sm-6">
                                          <span className="intotimesdown">
                                            {selection.selection_odds}
                                          </span>
                                        </div>
                                        <div className="col col-md-4 col-sm-6">
                                          <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            className="betinputfield"
                                            id={
                                              "stakevalueforselction" +
                                              selection.sid
                                            }
                                            // onChange={(e) =>
                                            //   this.betInputFieldChange(
                                            //     e,
                                            //     selection.sid,
                                            //     selection.selection_odds
                                            //   )
                                            // }
                                          />
                                        </div>
                                        <div className="col col-md-3 col-sm-6">
                                          <button
                                            className="placebetbtn"
                                            onClick={() => {
                                              this.placeBet(
                                                this.state.mands[key][0].mid,
                                                selection.sid,
                                                $(
                                                  "#stakevalueforselction" +
                                                    selection.sid
                                                ).val(),
                                                selection.selection_odds
                                              );
                                              this.hideorshowbetpanel(
                                                "m" + selection.sid
                                              );
                                            }}
                                          >
                                            Place Bet
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row stakes">
                                        {this.state.stakes.map((stake) => {
                                          return (
                                            <div
                                              className="col col-md-1 col-sm-4"
                                              key={stake[0]}
                                            >
                                              <button
                                                name="stack_value"
                                                className="stakeinput"
                                                onClick={() =>
                                                  this.changestakevalue(
                                                    stake[1],
                                                    selection.sid,
                                                    selection.selection_odds
                                                  )
                                                }
                                              >
                                                {stake[1]}
                                              </button>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        )}

        <Modal open={this.state.ruleModal} onClose={this.closeRuleModal}>
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                onClick={this.closeRuleModal}
                style={{ textAlign: "right" }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div
                className="row modalcontent1"
                style={{ overflowY: "hidden" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <h3>Rules</h3>
                  <hr />
                  <div
                    style={{
                      textAlign: "left",
                      height: "350px",
                      overflowY: "auto",
                    }}
                  >
                    <div>{parse(`${this.state.rules}`)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

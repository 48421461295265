import React, { useState, useEffect } from "react";
import "./Home.css";
import {base_url} from "../constants"
import { Link, useParams } from "react-router-dom";

const Games = () => {
  const userData = JSON.parse(localStorage.getItem("token"));
  const [balance, setBalance] = useState(0);
  const [gameData, setGameData] = useState();
  const [basePath, setBasePath] = useState();
  const { gameId } = useParams();

  useEffect(()=>{
    if (localStorage.getItem("token")) {
      const obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };
      fetch(base_url + "/welcome/refreshBalance", requestOptions)
        .then((res) => res.json())
        .then((json) => {
          setBalance(json.data[0]);
        })
        .catch((err) => console.log(err));
    }
  },[])

  useEffect(() => {
    const obj = { rid: gameId };
    const requestOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/welcome/getGames", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setGameData(json.data);
        setBasePath(json.base_path);
      })
      .catch((err) => console.log(err));
  }, [gameId]);

  return (
    <div className="gamesmain">
      <div className="row headrow">
        <div className="col-md-8 col-sm-12">
          <div className="row">
            <div className="col-md-2 col-sm-2">
              <Link to="/">
                <span className="fa fa-home orangecolor"></span>
              </Link>
            </div>
            <div className="col-md-4 col-sm-10">
              <p style={{ color: "#fff" }}>
                <span className="fa fa-user orangecolor"></span>{" "}
                {userData.email}
              </p>
            </div>
            <div className="col col-md-3 col-sm-6">
              <p className="orangecolor">
                Balance <span style={{ color: "#fff" }}>{balance.points}</span>
              </p>
            </div>
            <div className="col col-md-3 col-sm-6">
              <p className="orangecolor">
                Exposure{" "}
                <span style={{ color: "#fff" }}>({balance.exposure})</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12"></div>
      </div>

      <div className="row datarow">
        {gameData
          ? gameData.map((game) => {
              return (
                <div
                  className="col col-md-4 col-sm-12"
                  style={{ padding: "15px" }}
                >
                  <div className="roombox1">
                    <div className="row gboxup">
                      <div className="col col-4">
                        <img src={basePath + game.game_admin_img} alt="" />
                      </div>
                      <div className="col col-8" style={{ paddingTop: "40px" }}>
                        <h3>{game.gamename}</h3>
                      </div>
                    </div>
                    <div>
                      {game.status === "active" ? (
                        <Link to={`/games/module/${game.gid}`} type="button" className="btn roombtn1">
                          PLAY
                        </Link>
                      ) : (
                        <button disabled className="btn roombtn1">
                          PLAY
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default Games;

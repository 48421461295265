import { useState, useEffect } from "react";
import "./Home.css";
import { Link, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap";
import {base_url} from "../constants";

const Navbar = (props) => {
  const { handleOpenLoginModal, modalLogin, gameList, setLoginValue } = props;
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userData, setUserData] = useState("");
  const [points, setPoints] = useState(0);
  const [exposure, setExposure] = useState(0);
  const [stakes, setStakes] = useState([
    ["stake1", 0],
    ["stake2", 0],
    ["stake3", 0],
    ["stake4", 0],
    ["stake5", 0],
    ["stake6", 0],
    ["stake7", 0],
    ["stake8", 0],
  ]);
  const [error, setError] = useState("");

  if (localStorage.getItem("token") && login === false) {
    setLogin((localStorage.getItem("token") ? true : false) || modalLogin);
    setLoginValue((localStorage.getItem("token") ? true : false) || modalLogin);
    setUserData(JSON.parse(localStorage.getItem("token")));
    //--------------------------------API----------------------------------

    // -------------------------Stakes----------------------------
    const obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
    const requestOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/welcome/getStakeList", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        let arr = [];
        for (let keys in json.data[0]) {
          arr.push([keys, json.data[0][keys]]);
        }
        setStakes(arr);
        localStorage.setItem("stakes", JSON.stringify(json.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const refreshBalance = () => {
    if (localStorage.getItem("token")) {
      const obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };
      fetch(base_url + "/welcome/refreshBalance", requestOptions)
        .then((res) => res.json())
        .then((json) => {
          setPoints(json.data[0].points);
          setExposure(json.data[0].exposure);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(refreshBalance, []);

  const formValidate = () => {
    if (email === "") {
      setError("Enter a valid Email-Id.");
      alert("Enter a valid Email-Id.");
      return false;
    } else if (password === "") {
      setError("Enter a valid Password.");
      alert("Enter a valid Password.");
      return false;
    }
    return true;
  };

  // --------------------Updating Stakes-----------------------------------------
  const onSave = () => {
    const obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
    for (let i = 0; i < stakes.length; i++) {
      let keys = stakes[i][0];
      let value = stakes[i][1];
      obj[keys] = value;
    }

    const requestOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/welcome/updateStakes", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        let arr = [];
        for (let keys in json.data[0]) {
          arr.push([keys, json.data[0][keys]]);
        }
        setStakes(arr);
        localStorage.setItem("stakes", JSON.stringify(json.data));
        document.getElementById("set_pop").style.display = "none";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ------------------------------LOGIN------------------------------------------
  const login_req = (e) => {
    if (formValidate()) {
      let obj = { email: email, password: password };
      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };

      e.preventDefault();
      fetch(base_url + "/welcome/userLogin", requestOptions)
        .then((res) => res.json())
        .then((json) => {
          if (json.status === 200) {
            localStorage.setItem("token", JSON.stringify(json.data[0]));
            setLogin(true || modalLogin);
            setUserData(json.data[0]);
            setLoginValue(true || modalLogin);
            setError("");
            setEmail("");
            setPassword("");
            window.location.reload();
          } else {
            setError(json.message);
            alert(json.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useState(() => {}, [userData]);
  // --------------------------------------XXXXXX-----------------------------------------------
  const handleChange = (e) => {
    if (e.target.name === "emailid") {
      setEmail(e.target.value);
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const handleChangeStake = (e) => {
    const { name, value } = e.target;
    let idx = Number(name);
    let arr = [...stakes];
    arr[idx] = [...arr[idx]];
    arr[idx][1] = value;
    setStakes(arr);
  };

  const handleLogout = () => {
    localStorage.clear();
    setEmail("");
    setPassword("");
    setLogin(false && modalLogin);
    <Redirect to="/" />;
  };

  // const handleClickOutside = (event) => {
  //   const concernedElement = document.querySelector("#set_pop");
  //   if (concernedElement.contains(event.target)) {
  //     document.getElementById("set_pop").style.display = "block";
  //   } else {
  //     document.getElementById("set_pop").style.display = "none";
  //   }
  // };

  const showStakes = () => {
    const v = document.getElementById("set_pop").style.display;

    if (v === "none" || v === "") {
      document.getElementById("set_pop").style.display = "block";
    } else {
      document.getElementById("set_pop").style.display = "none";
    }
    // document.addEventListener("mousedown", handleClickOutside, false);

    // document.removeEventListener("mousedown", handleClickOutside, false);
  };

  return (
    <>
      {login ? (
        <div>
          <nav className="navbar navbar-light bg-light justify-content-between">
            <Link to="/" className="navbar-brand">
              <img src="assets/images/logosm.jpg" alt="46Sports" />
            </Link>
            <ul className="nav navbar-nav ml-auto p-rltv">
              <div className="user-blnc">
                <span>
                  Points : <strong>{points}</strong>
                </span>
                <span className="exp-topcount">
                  Exposure{" "}
                  <strong>
                    (<span className="exp-red">{exposure}</span>)
                  </strong>
                </span>
                <span>
                  <h6
                    className="btn btn-success a-referesh"
                    title="Setting"
                    style={{ marginTop: "9px" }}
                    onClick={refreshBalance}
                  >
                    <img
                      src="assets/images/reload.png"
                      alt="refresh"
                      style={{ width: "15px" }}
                    />
                  </h6>
                </span>
              </div>
            </ul>
            <li
              className="account-wrap-new dropdown hidden-xs"
              dropdown=""
              placement="bottom right"
              style={{ paddingTop: "9px" }}
            >
              <h6
                aria-haspopup="true"
                className="nav-link myaccnt"
                data-toggle="dropdown"
                dropdowntoggle=""
                id="accountPopup"
                role="button"
                style={{ cursor: "pointer" }}
                aria-expanded="false"
              >
                <i className="fa fa-user"></i> My Account
                <i className="fa fa-chevron-down"></i>
              </h6>
              <div
                aria-labelledby="simple-dropdown"
                className="dropdown-menu dropdown-menu-right"
                style={{ left: "auto", right: "20px" }}
              >
                <div className="dropdown-header text-left">
                  <strong>User Name</strong>
                </div>
                <Link to="/profile" className="dropdown-item">
                  My Profile
                </Link>
                <Link to="/account" className="dropdown-item">
                  Account Statement
                </Link>
                <Link to="/betHistory" className="dropdown-item">
                  Profit-Loss &amp; Bet History
                </Link>
                <Link to="/" className="dropdown-item drp-logout">
                  <button
                    className="btn btn-primary logout-btn"
                    onClick={handleLogout}
                  >
                    LOGOUT
                    <i aria-hidden="true" className="fa fa-sign-out ml-2"></i>
                  </button>
                </Link>
              </div>
            </li>
          </nav>

          <div className="menu-wrap">
            <div className="row">
              <div className="col-md-12">
                <ul className="tab-menu pull-left">
                  <li className="ver-menu">
                    <Link to="/" style={{ textDecoration: "none" }}>
                      Home
                    </Link>
                  </li>
                  {gameList && gameList.data
                    ? gameList.data.map((game) => {
                        return (
                          <li className="ver-menu" key={game.tid}>
                            {login ? (
                              <Link
                                to={"/rooms/" + game.tid}
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                              >
                                {game.typename}
                              </Link>
                            ) : (
                              <h6
                                className="Casino newlaunch"
                                onClick={handleOpenLoginModal}
                                style={{
                                  textDecoration: "none",
                                  color: "#ffff",
                                }}
                              >
                                {game.typename}
                              </h6>
                            )}
                          </li>
                        );
                      })
                    : ""}
                </ul>
                <ul className="tab-menu pull-right">
                  <li className="ver-menu settingstack" onClick={showStakes}>
                    <h6
                      className="active"
                      style={{ color: "#ffff", fontWeigth: "700" }}
                    >
                      Setting <i className="fa fa-cog"></i>
                    </h6>
                  </li>
                </ul>
                <div className="slip_set-pop" id="set_pop">
                  <div className="overlay right-side">
                    <div className="side-wrap setting-wrap" id="settingSlide">
                      <div className="side-content" id="coinList">
                        <h3 className="stakeheading">Stake</h3>
                        <dl
                          className="setting-block stake-setting"
                          id="editCustomizeStakeList"
                        >
                          {stakes.map((stake, idx) => {
                            return (
                              <dd key={stake[0]}>
                                <input
                                  name={idx}
                                  type="text"
                                  value={stake[1]}
                                  onChange={handleChangeStake}
                                />
                              </dd>
                            );
                          })}
                          <dd className="col-stake_edit">
                            <h6
                              className="btn-send ui-link"
                              href="#!"
                              id="save"
                              onClick={onSave}
                            >
                              Save
                            </h6>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <nav className="navbar navbar-light bg-light justify-content-between">
            <Link to="/" className="navbar-brand">
              <img src="assets/images/logosm.jpg" alt="46Sports" />
            </Link>
            <form className="form-inline header-form">
              <input
                className="form-control mr-sm-2 umail"
                type="email"
                name="emailid"
                placeholder="Email-Id"
                onChange={handleChange}
              />
              <input
                className="form-control mr-sm-2 upass"
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
              />
              <button
                type="button"
                onClick={login_req}
                className="btn btn-orange pull-right mr-2"
              >
                Login <span className="fa fa-sign-in-alt"></span>
              </button>
            </form>
            <button
              type="button"
              className="btn btn-orange pull-right mr-2 mobile_login"
              onClick={handleOpenLoginModal}
            >
              Login <span className="fa fa-sign-in-alt"></span>
            </button>
          </nav>
          <div className={`menu-wrap ${login ? "" : "extrastyle"}`}>
            <div className="row">
              <div className="col-md-8">
                <ul className="tab-menu pull-left">
                  <li className="ver-menu">
                    <Link to="/" style={{ textDecoration: "none" }}>
                      Home
                    </Link>
                  </li>
                  {gameList && gameList.data
                    ? gameList.data.map((game) => {
                        return (
                          <li className="ver-menu" key={game.tid}>
                            <h6
                              className="Casino newlaunch"
                              onClick={() => {
                                login ? (
                                  <Redirect to="/games" />
                                ) : (
                                  handleOpenLoginModal()
                                );
                              }}
                              style={{
                                color: "white",
                                textDecoration: "none",
                                fontWeight: "700",
                              }}
                            >
                              {game.typename}
                            </h6>
                          </li>
                        );
                      })
                    : ""}
                </ul>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;

import React, { useState, useEffect } from "react";
import SidebarLeft from "./SidebarLeft";
import SidebarRight from "./SidebarRight";
import "./Home.css";
import {base_url} from "../constants"
import Navbar from "./Navbar";
import { AgGridColumn, AgGridReact } from "ag-grid-react"; 
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const Account = (props) => {
  const { gameList, basicInfo, setLoginValue } = props;
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const obj = { toid: JSON.parse(localStorage.getItem("token")).uid };

    const requestOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/welcome/getMoneyCoinsTransactions", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setRowData(json.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const valueGetterFrom = (params) => {
    return `${params.data.admin_name} => ${
      JSON.parse(localStorage.getItem("token")).name
    }`;
  };

  const valueGetterDate = (params) => {
    return `${params.data.datetime}`;
  };

  return (
    <div className="home">
      <Navbar gameList={gameList} setLoginValue={setLoginValue} />
      <div className="row mainbody">
        <SidebarLeft gameList={gameList} />
        <div className="col-md-6 col-sm-12 midbar">
          <div className="card card-accent-primary">
            <div className="card-header account-detail-head">
              Account Statement
            </div>
            <div className="card-body account-detail-body">
              <div className="bd-example">
                <div
                  className="ag-theme-alpine"
                  style={{ height: 400, width: "100%" }}
                >
                  <AgGridReact
                    rowData={rowData}
                    pagination={true}
                    paginationPageSize={10}
                  >
                    <AgGridColumn
                      headerName="Date/Time"
                      sortable={true}
                      filter={true}
                      valueGetter={valueGetterDate}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="deposit"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="withdraw"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="balance"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="remarks"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="From => To"
                      sortable={true}
                      filter={true}
                      valueGetter={valueGetterFrom}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SidebarRight basicInfo={basicInfo} />
      </div>
    </div>
  );
};

export default Account;

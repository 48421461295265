import React, { Component } from "react";
import "./games.css";
import Modal from "@material-ui/core/Modal";
import parse from "html-react-parser";
import { base_url } from "../../constants";
import { Link } from "react-router-dom";
import { $ } from "react-jquery-plugin";
import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default class AndarBahar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_stake_put_24: 0,
      total_stake_put_25: 0,
      total_stake_put_26: 0,
      total_stake_put_27: 0,
      total_stake_put_28: 0,
      total_stake_put_29: 0,
      total_stake_put_30: 0,
      total_stake_put_31: 0,
      total_stake_put_32: 0,
      total_stake_put_33: 0,
      total_stake_put_34: 0,
      total_stake_put_35: 0,
      total_stake_put_36: 0,
      total_stake_put_37: 0,
      total_stake_put_38: 0,
      total_stake_put_39: 0,
      total_stake_put_40: 0,
      total_stake_put_41: 0,
      total_stake_put_42: 0,
      total_stake_put_43: 0,
      total_stake_put_44: 0,
      total_stake_put_45: 0,
      total_stake_put_46: 0,
      stakes: [
        [
          ["stake1", 0],
          ["stake2", 0],
          ["stake3", 0],
          ["stake4", 0],
          ["stake5", 0],
          ["stake6", 0],
          ["stake7", 0],
          ["stake8", 0],
        ],
      ],
      ruleModal: false,
      rules: "",
      balance: { points: "0", exposure: "0" },
      basePath: "",
      mands: [],
      roundId: "",
      timer: 0,
      masterCard: "",
      andarCards: [],
      baharCards: [],
      showTimer: "",
      timerSI: "",
      betSI: "",
      cardNo: 0,
      result: "",
    };
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  getRefreshBalance() {
    let obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
    const refreshOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/welcome/refreshBalance", refreshOptions)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ balance: json.data[0] });
      })
      .catch((err) => console.log(err));
  }

  getLatestRound() {
    const roundOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
    };

    fetch(base_url + "/andarbahar/getlatestround", roundOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.status === 200) {
          this.setState({
            roundId: json.data.roundid,
            timer: json.data.timmer,
            result: json.data.result,
            masterCard: json.data.mastercardimg,
          });
          this.startTimer();
        } else {
          this.setState({ roundMessage: json.message });
        }
      })
      .catch((err) => console.log(err));
  }

  getMasterCard() {
    const obj = { roundid: this.state.roundId };

    const roundOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/andarbahar/get_master_card", roundOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.status === 200) {
          console.log("master" + json);
          this.setState({ masterCard: json.data.imgname });
        }
      })
      .catch((err) => console.log(err));
  }

  getAndarCard() {
    const obj = { roundid: this.state.roundId };

    const roundOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/andarbahar/get_latest_andar_card", roundOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.status === 200) {
          console.log("andar" + json);
          this.setState({ andarCards: json.card, result: json.result });
        }
      })
      .catch((err) => console.log(err));
  }

  getBaharCard() {
    const obj = { roundid: this.state.roundId };

    const roundOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/andarbahar/get_latest_bahar_card", roundOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.status === 200) {
          console.log("bahar" + json);
          this.setState({ baharCards: json.card, result: json.result });
        }
      })
      .catch((err) => console.log(err));
  }

  openRuleModal = () => {
    this.setState({ ruleModal: true });
  };

  closeRuleModal = () => {
    this.setState({ ruleModal: false });
  };

  hideorshowbetpanel(panelname) {
    var currentvalue = $("#" + panelname).css("display");
    $(".optionsforbetplacing").css("display", "none");
    if (currentvalue === "none") {
      $("#" + panelname).css("display", "block");
    } else {
      $("#" + panelname).css("display", "none");
    }
  }

  changestakevalue(valueofstake, idnum, selection_odds) {
    $("#stakevalueforselction" + idnum).val(valueofstake);
    let profit_value = valueofstake * selection_odds - valueofstake;
    $("#p" + idnum).css("color", "green");
    $("#p" + idnum).html("P = " + profit_value);
  }

  changestakevalueAndarBahar(valueofstake, idnum) {
    $("#stakevalueforselction" + idnum).val(valueofstake);
  }

  betInputFieldChange(e, idnum, selection_odds) {
    let profit_value = e.target.value * selection_odds - e.target.value;
    $("#p" + idnum).css("color", "green");
    $("#p" + idnum).html("P = " + profit_value);
  }

  placeBet(marketId, selectionId, stakes, selection_odds) {
    if (parseInt(stakes) < 100) {
      alert("Minimum of 100 stake points are required to place a bet.");
    } else if (parseInt(this.state.balance.points) < parseInt(stakes)) {
      alert("You do not have enough stake points to place bet.");
    } else {
      // Changing the value of P
      var c_stake = this.state['total_stake_put_' + selectionId];
      c_stake += parseInt(stakes);
      this.state['total_stake_put_' + selectionId] = parseInt(c_stake);
      let profit_value = c_stake * selection_odds - c_stake;
      $("#p" + selectionId).css("color", "green");
      $("#p" + selectionId).html("P = " + profit_value);
      // Changing the value of P
      let betObj = {
        uid: JSON.parse(localStorage.getItem("token")).uid,
        gid: this.props.location.pathname.split("/")[3],
        mid: marketId,
        sid: selectionId,
        roundid: this.state.roundId,
        stake: parseInt(stakes),
      };

      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(betObj),
      };

      fetch(base_url + "/andarbahar/insertbetinfo", requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          // console.log(json);
        })
        .catch((err) => {
          console.log(err);
        });

      setTimeout(() => {
        this.getRefreshBalance();
      }, 1000);
    }
  }

  showStakes() {
    const v = document.getElementById("set_pop").style.display;

    if (v === "none" || v === "") {
      document.getElementById("set_pop").style.display = "block";
    } else {
      document.getElementById("set_pop").style.display = "none";
    }
  }

  handleChangeStake = (e) => {
    const { name, value } = e.target;
    let idx = Number(name);
    let arr = [...this.state.stakes];
    arr[idx] = [...arr[idx]];
    arr[idx][1] = value;
    this.setState({ stakes: arr });
  };

  onSave = () => {
    const obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
    for (let i = 0; i < this.state.stakes.length; i++) {
      let keys = this.state.stakes[i][0];
      let value = this.state.stakes[i][1];
      obj[keys] = value;
    }

    const requestOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url + "/welcome/updateStakes", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        let arr = [];
        for (let keys in json.data[0]) {
          arr.push([keys, json.data[0][keys]]);
        }
        this.setState({ stakes: arr });
        localStorage.setItem("stakes", JSON.stringify(json.data));
        document.getElementById("set_pop").style.display = "none";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  startTimer() {
    if (this.state.timer <= 70 && this.state.timer > 40) {
      this.setState({ showTimer: this.state.timer - 40 });
      let roundTimer = setInterval(this.countDown, 1000);
      this.setState({ timerSI: roundTimer });
    } else if (this.state.timer <= 40 && this.state.timer > 35) {
      this.hideorshowbetpanel("closeall");
      let wait_timer = setInterval(() => {
        if (this.state.timer === 40) {
          this.setState({ timer: this.state.timer - 1 });
        } else {
          this.state.timer = this.state.timer - 1;
        }
        if (this.state.timer <= 35) {
          clearInterval(wait_timer);
          this.startTimer();
          this.getMasterCard();
        }
      }, 1000);
    } else if (this.state.timer <= 35 && this.state.timer > 20) {
      this.setState({ showTimer: this.state.timer - 20 });
      if (this.state.masterCard === "") {
        this.getMasterCard();
      }
      let roundTimer = setInterval(this.countDown, 1000);
      this.setState({ timerSI: roundTimer });
    } else if (this.state.timer <= 20 && this.state.timer > 15) {
      this.hideorshowbetpanel("closeall");
      if (this.state.masterCard === "") {
        this.getMasterCard();
      }
      let betSI = setInterval(() => {
        this.state.timer = this.state.timer - 1;
        if (this.state.timer === 19) {
          this.getAndarCard();
        }
        if (this.state.timer <= 17 && this.state.result === "") {
          this.getAndarCard();
          this.getBaharCard();
        }
        if (this.state.timer <= 15) {
          clearInterval(this.state.betSI);
          this.startTimer();
        }
      }, 1000);
      this.setState({ betSI: betSI });
    } else if (
      this.state.result === "" &&
      this.state.timer <= 15 &&
      this.state.timer > 0
    ) {
      if (this.state.masterCard === "") {
        this.getMasterCard();
      }
      this.getAndarCard();
      this.getBaharCard();
      this.setState({ showTimer: this.state.timer });
      let roundTimer = setInterval(this.countDown, 1000);
      this.setState({ timerSI: roundTimer });
    } else {
      if (this.state.result === "") {
        let roundTimer = setInterval(() => {
          if (this.state.cardNo % 2 === 0) {
            this.getAndarCard();
          } else {
            this.getBaharCard();
          }
          this.setState({ cardNo: this.state.cardNo + 1 });

          if (this.state.result !== "") {
            clearInterval(roundTimer);
            this.startTimer();
          }
        }, 1000);
      } else {
        this.setState({
          roundMessage:
            "Updating point and exposure. Please wait for the next game to start.",
        });
        setTimeout(() => {
          window.location.reload();
        }, 12000);
        this.state.total_stake_put_24 = 0;
        this.state.total_stake_put_25 = 0;
        this.state.total_stake_put_26 = 0;
        this.state.total_stake_put_27 = 0;
        this.state.total_stake_put_28 = 0;
        this.state.total_stake_put_29 = 0;
        this.state.total_stake_put_30 = 0;
        this.state.total_stake_put_31 = 0;
        this.state.total_stake_put_32 = 0;
        this.state.total_stake_put_33 = 0;
        this.state.total_stake_put_34 = 0;
        this.state.total_stake_put_35 = 0;
        this.state.total_stake_put_36 = 0;
        this.state.total_stake_put_37 = 0;
        this.state.total_stake_put_38 = 0;
        this.state.total_stake_put_39 = 0;
        this.state.total_stake_put_40 = 0;
        this.state.total_stake_put_41 = 0;
        this.state.total_stake_put_42 = 0;
        this.state.total_stake_put_43 = 0;
        this.state.total_stake_put_44 = 0;
        this.state.total_stake_put_45 = 0;
        this.state.total_stake_put_46 = 0;
      }
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens
    this.setState({ showTimer: this.state.showTimer - 1 });

    this.state.timer = this.state.timer - 1;

    // Check if we're at zero.
    if (this.state.showTimer === 0) {
      clearInterval(this.state.timerSI);
      this.startTimer();
    }

    if (this.state.timer === 0 || this.state.timer === 40) {
      this.hideorshowbetpanel("closeall");
    }
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      let obj = { uid: JSON.parse(localStorage.getItem("token")).uid };
      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };

      fetch(base_url + "/welcome/getStakeList", requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          let arr = [];
          for (let keys in json.data[0]) {
            arr.push([keys, json.data[0][keys]]);
          }
          this.setState({ stakes: arr });
          localStorage.setItem("stakes", JSON.stringify(json.data));
        })
        .catch((err) => {
          console.log(err);
        });

      fetch(base_url + "/welcome/refreshBalance", requestOptions)
        .then((res) => res.json())
        .then((json) => {
          this.setState({ balance: json.data[0] });
        })
        .catch((err) => console.log(err));

      const roundOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
      };

      fetch(base_url + "/andarbahar/getlatestround", roundOptions)
        .then((res) => res.json())
        .then((json) => {
          if (json.status === 200) {
            this.setState({
              roundId: json.data.roundid,
              timer: parseInt(json.data.timmer),
              result: json.data.result,
              masterCard: json.data.mastercardimg,
            });
            this.startTimer();
          } else {
            this.setState({ roundMessage: json.message });
          }
        })
        .catch((err) => console.log(err));

      const options = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify({
          moduleid: this.props.location.pathname.split("/")[3],
        }),
      };

      fetch(base_url + "/welcome/getmarketandselections", options)
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            basePath: json.base_path,
            rules: json.data.rules,
            mands: json.data.mands,
          });
        })
        .catch((err) => console.log(err));
    }
  }

  render() {
    return (
      <div className="gamesmain">
        <div className="row gameheadrow">
          <div className="col-md-2 col-sm-12"></div>
          <div className="col-md-8 col-sm-12">
            <div className="row">
              <div className="col col-md-1 col-sm-1">
                <ul className="tab-menu" style={{ display: "inline" }}>
                  <li className="ver-menu settingstack">
                    <Link to="/" className="active">
                      <i
                        className="fa fa-home"
                        style={{ fontSize: "16px" }}
                      ></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col col-md-1 col-sm-1">
                <ul className="tab-menu" style={{ display: "inline" }}>
                  <li
                    className="ver-menu settingstack"
                    onClick={this.showStakes}
                  >
                    <h6 className="active">
                      <i className="fa fa-cog" style={{ fontSize: "16px" }}></i>
                    </h6>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-10">
                <p style={{ color: "#fff" }}>
                  <span className="fa fa-user orangecolor"></span>{" "}
                  {JSON.parse(localStorage.getItem("token")).email}
                </p>
              </div>
              <div className="col col-md-3 col-sm-6">
                <p className="orangecolor">
                  Balance{" "}
                  <span style={{ color: "#fff" }}>
                    {this.state.balance.points}
                  </span>
                </p>
              </div>
              <div className="col col-md-3 col-sm-6">
                <p className="orangecolor">
                  Exposure{" "}
                  <span style={{ color: "#fff" }}>
                    ({this.state.balance.exposure})
                  </span>
                </p>
              </div>
              <div className="slip_set-pop" id="set_pop">
                <div className="overlay right-side">
                  <div className="side-wrap setting-wrap" id="settingSlide">
                    <div className="side-content" id="coinList">
                      <h3 className="stakeheading">Stake</h3>
                      <dl
                        className="setting-block stake-setting"
                        id="editCustomizeStakeList"
                      >
                        {this.state.stakes.map((stake, idx) => {
                          return (
                            <dd key={stake[0]}>
                              <input
                                name={idx}
                                type="text"
                                value={stake[1]}
                                onChange={this.handleChangeStake}
                              />
                            </dd>
                          );
                        })}
                        <dd className="col-stake_edit">
                          <h6
                            className="btn-send ui-link"
                            id="save"
                            onClick={this.onSave}
                          >
                            Save
                          </h6>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-12"></div>
        </div>
        <div className="row">
          <div className="col-md-2 col-sm-12"></div>
          <div className="col col-md-8 gamearea">
            <div className="row">
              <h6
                className="rules"
                style={{ width: "auto" }}
                onClick={this.openRuleModal}
              >
                RULES <i className="fa fa-info-circle"></i>
              </h6>
              <div className="roundid">
                Round :{" "}
                {this.state.roundId ? this.state.roundId : "No Round Available"}
              </div>
              <div className="col-12 timmerboxab">
                {" "}
                <span className="timmerab" id="timmer">
                  {this.state.showTimer ? this.state.showTimer : 0}
                </span>{" "}
              </div>
              <div className="col col-md-12 col-sm-12 cardarea">
                <div className="row">
                  <div
                    className="col-md-2 col-sm-12 mastercard"
                    id="mastercard"
                  >
                    {" "}
                    <img
                      alt=""
                      src={"/assets/images/cards/" + this.state.masterCard}
                      style={{
                        height: "125px",
                        width: "auto",
                        borderRadius: "5px",
                      }}
                    />{" "}
                  </div>
                  <div
                    className="col-md-10 col-sm-12 ab_card_array"
                    id="ab_card_array"
                  >
                    <div className="a_card_array" id="a_card_array">
                      <h4 className="ab_title">Andar</h4>{" "}
                      {this.state.result === "A" ? (
                        <h4 style={{ color: "#E16E38" }}>Winner</h4>
                      ) : (
                        ""
                      )}
                      <hr />
                      {this.state.andarCards
                        ? this.state.andarCards.map((cards) => {
                            return (
                              <img
                                alt=""
                                src={"/assets/images/cards/" + cards.imgname}
                                className="ab_card"
                              />
                            );
                          })
                        : ""}
                      {/* <img src="/assets/images/cards/2-black-spade.jpg" className="ab_card" /> <img src="/assets/images/cards/11-red-diamond.jpg" className="ab_card" /> <img src="/assets/images/cards/11-black-spade.jpg" className="ab_card" /> <img src="/assets/images/cards/13-black-spade.jpg" className="ab_card" /> <img src="/assets/images/cards/8-red-heart.jpg" className="ab_card" /> <img src="/assets/images/cards/12-red-heart.jpg" className="ab_card" /> */}
                    </div>
                    <div className="b_card_array" id="b_card_array">
                      <h4 className="ab_title">Bahar</h4>{" "}
                      {this.state.result === "B" ? (
                        <h4 style={{ color: "#E16E38" }}>Winner</h4>
                      ) : (
                        ""
                      )}
                      <hr />
                      {this.state.baharCards
                        ? this.state.baharCards.map((cards) => {
                            return (
                              <img
                                alt=""
                                src={"/assets/images/cards/" + cards.imgname}
                                className="ab_card"
                              />
                            );
                          })
                        : ""}
                      {/* <img src="/assets/images/cards/9-red-heart.jpg" className="ab_card" /> <img src="/assets/images/cards/11-red-heart.jpg" className="ab_card" /> <img src="/assets/images/cards/13-black-spade.jpg" className="ab_card" /> <img src="/assets/images/cards/4-black-spade.jpg" className="ab_card" /> <img src="/assets/images/cards/2-red-heart.jpg" className="ab_card" /> </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!this.state.roundMessage ? (
          <div className="row">
            <div className="col-md-2 col-sm-12"></div>
            <div className="col col-md-8 marketarea">
              <div className="accordion acccustomstyle" id="">
                {this.state.mands
                  ? Object.keys(this.state.mands).map((key) => {
                      if (this.state.mands[key][0].mid === "6") {
                        return (
                          <div
                            className="card"
                            key={this.state.mands[key][0].mid}
                          >
                            <div
                              className="card-header"
                              id={"heading" + this.state.mands[key][0].mid}
                            >
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left marketbtn"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target={
                                    "#collapse" + this.state.mands[key][0].mid
                                  }
                                  aria-expanded="true"
                                  aria-controls={
                                    "#collapse" + this.state.mands[key][0].mid
                                  }
                                >
                                  {key}
                                </button>
                              </h2>
                            </div>
                            <div
                              id={"collapse" + this.state.mands[key][0].mid}
                              className="collapse show"
                              aria-labelledby={
                                "heading" + this.state.mands[key][0].mid
                              }
                              data-parent="#marketaccordion"
                            >
                              <div className="card-body">
                                <div className="marketrow">
                                  <div className="row">
                                    <div
                                      className="col-md-6 col-sm-12"
                                      style={{
                                        textAlign: "center",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div className="markettitle"> Andar </div>
                                      {this.state.timer > 20 &&
                                      this.state.timer <= 35 ? (
                                        <div
                                          className="marketbetname"
                                          onClick={() =>
                                            this.hideorshowbetpanel("a1b")
                                          }
                                        >
                                          {" "}
                                          1<sup>st</sup> Bet{" "}
                                        </div>
                                      ) : (
                                        <div
                                          className="marketbetname"
                                          style={{
                                            backgroundColor: "#777",
                                            border: "none",
                                          }}
                                        >
                                          {" "}
                                          1<sup>st</sup> Bet{" "}
                                        </div>
                                      )}
                                      {this.state.timer > 0 &&
                                      this.state.timer <= 15 ? (
                                        <div
                                          className="marketbetname"
                                          onClick={() =>
                                            this.hideorshowbetpanel("a2b")
                                          }
                                        >
                                          {" "}
                                          2<sup>nd</sup> Bet{" "}
                                        </div>
                                      ) : (
                                        <div
                                          className="marketbetname"
                                          style={{
                                            backgroundColor: "#777",
                                            border: "none",
                                          }}
                                        >
                                          {" "}
                                          2<sup>nd</sup> Bet{" "}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className="col-md-6 col-sm-12"
                                      style={{
                                        textAlign: "center",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div className="markettitle"> Bahar </div>
                                      {this.state.timer > 20 &&
                                      this.state.timer <= 35 ? (
                                        <div
                                          className="marketbetname"
                                          onClick={() =>
                                            this.hideorshowbetpanel("b1b")
                                          }
                                        >
                                          {" "}
                                          1<sup>st</sup> Bet{" "}
                                        </div>
                                      ) : (
                                        <div
                                          className="marketbetname"
                                          style={{
                                            backgroundColor: "#777",
                                            border: "none",
                                          }}
                                        >
                                          {" "}
                                          1<sup>st</sup> Bet{" "}
                                        </div>
                                      )}
                                      {this.state.timer <= 15 &&
                                      this.state.timer > 0 ? (
                                        <div
                                          className="marketbetname"
                                          onClick={() =>
                                            this.hideorshowbetpanel("b2b")
                                          }
                                        >
                                          {" "}
                                          2<sup>nd</sup> Bet{" "}
                                        </div>
                                      ) : (
                                        <div
                                          className="marketbetname"
                                          style={{
                                            backgroundColor: "#777",
                                            border: "none",
                                          }}
                                        >
                                          {" "}
                                          2<sup>nd</sup> Bet{" "}
                                        </div>
                                      )}
                                    </div>
                                    {/*<!-- bets panel --> */}
                                    <div
                                      className="optionsforbetplacing"
                                      id="a1b"
                                    >
                                      <div className="row">
                                        <div className="col col-md-3 col-sm-6">
                                          1<sup>st</sup> Bet Andar
                                        </div>
                                        <div className="col col-md-1 col-sm-6">
                                          <button
                                            className="cancelbtn"
                                            onClick={() =>
                                              this.hideorshowbetpanel(
                                                "closeall"
                                              )
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                        <div className="col col-md-2 col-sm-6">
                                          <span className="intotimesdown">
                                            {
                                              this.state.mands[key][0]
                                                .selection_odds
                                            }
                                          </span>
                                        </div>
                                        <div className="col col-md-3 col-sm-6">
                                          <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            className="betinputfield"
                                            id="stakevalueforselction0"
                                          />
                                        </div>
                                        <div className="col col-md-2 col-sm-6">
                                          <button
                                            className="placebetbtn"
                                            onClick={() => {
                                              this.placeBet(
                                                this.state.mands[key][0].mid,
                                                this.state.mands[key][0].sid,
                                                $(
                                                  "#stakevalueforselction0"
                                                ).val(),
                                                this.state.mands[key][0].selection_odds
                                              );
                                              this.hideorshowbetpanel(
                                                "closeall"
                                              );
                                            }}
                                          >
                                            Place Bet
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row stakes">
                                        {this.state.stakes.map((stake) => {
                                          return (
                                            <div
                                              className="col col-md-1 col-sm-4"
                                              key={stake[0]}
                                            >
                                              <button
                                                name="stack_value"
                                                className="stakeinput"
                                                onClick={() =>
                                                  this.changestakevalueAndarBahar(
                                                    stake[1],
                                                    0
                                                  )
                                                }
                                              >
                                                {stake[1]}
                                              </button>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>

                                    <div
                                      className="optionsforbetplacing"
                                      id="a2b"
                                    >
                                      <div className="row">
                                        <div className="col col-md-3 col-sm-6">
                                          2<sup>nd</sup> Bet Andar
                                        </div>
                                        <div className="col col-md-1 col-sm-6">
                                          <button
                                            className="cancelbtn"
                                            onClick={() =>
                                              this.hideorshowbetpanel(
                                                "closeall"
                                              )
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                        <div className="col col-md-2 col-sm-6">
                                          <span className="intotimesdown">
                                            {
                                              this.state.mands[key][1]
                                                .selection_odds
                                            }
                                          </span>
                                        </div>
                                        <div className="col col-md-3 col-sm-6">
                                          <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            className="betinputfield"
                                            id="stakevalueforselction1"
                                          />
                                        </div>
                                        <div className="col col-md-2 col-sm-6">
                                          <button
                                            className="placebetbtn"
                                            onClick={() => {
                                              this.placeBet(
                                                this.state.mands[key][1].mid,
                                                this.state.mands[key][1].sid,
                                                $(
                                                  "#stakevalueforselction1"
                                                ).val(),
                                                this.state.mands[key][1].selection_odds
                                              );
                                              this.hideorshowbetpanel(
                                                "closeall"
                                              );
                                            }}
                                          >
                                            Place Bet
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row stakes">
                                        {this.state.stakes.map((stake) => {
                                          return (
                                            <div
                                              className="col col-md-1 col-sm-4"
                                              key={stake[0]}
                                            >
                                              <button
                                                name="stack_value"
                                                className="stakeinput"
                                                onClick={() =>
                                                  this.changestakevalueAndarBahar(
                                                    stake[1],
                                                    1
                                                  )
                                                }
                                              >
                                                {stake[1]}
                                              </button>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div
                                      className="optionsforbetplacing"
                                      id="b1b"
                                    >
                                      <div className="row">
                                        <div className="col col-md-3 col-sm-6">
                                          1<sup>st</sup> Bet Bahar
                                        </div>
                                        <div className="col col-md-1 col-sm-6">
                                          <button
                                            className="cancelbtn"
                                            onClick={() =>
                                              this.hideorshowbetpanel(
                                                "closeall"
                                              )
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                        <div className="col col-md-2 col-sm-6">
                                          <span className="intotimesdown">
                                            {
                                              this.state.mands[key][2]
                                                .selection_odds
                                            }
                                          </span>
                                        </div>
                                        <div className="col col-md-3 col-sm-6">
                                          <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            className="betinputfield"
                                            id="stakevalueforselction2"
                                          />
                                        </div>
                                        <div className="col col-md-2 col-sm-6">
                                          <button
                                            className="placebetbtn"
                                            onClick={() => {
                                              this.placeBet(
                                                this.state.mands[key][2].mid,
                                                this.state.mands[key][2].sid,
                                                $(
                                                  "#stakevalueforselction2"
                                                ).val(),
                                                this.state.mands[key][2].selection_odds
                                              );
                                              this.hideorshowbetpanel(
                                                "closeall"
                                              );
                                            }}
                                          >
                                            Place Bet
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row stakes">
                                        {this.state.stakes.map((stake) => {
                                          return (
                                            <div
                                              className="col col-md-1 col-sm-4"
                                              key={stake[0]}
                                            >
                                              <button
                                                name="stack_value"
                                                className="stakeinput"
                                                onClick={() =>
                                                  this.changestakevalueAndarBahar(
                                                    stake[1],
                                                    2
                                                  )
                                                }
                                              >
                                                {stake[1]}
                                              </button>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>

                                    <div
                                      className="optionsforbetplacing"
                                      id="b2b"
                                    >
                                      <div className="row">
                                        <div className="col col-md-3 col-sm-6">
                                          2<sup>nd</sup> Bet Bahar
                                        </div>
                                        <div className="col col-md-1 col-sm-6">
                                          <button
                                            className="cancelbtn"
                                            onClick={() =>
                                              this.hideorshowbetpanel(
                                                "closeall"
                                              )
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                        <div className="col col-md-2 col-sm-6">
                                          <span className="intotimesdown">
                                            {
                                              this.state.mands[key][3]
                                                .selection_odds
                                            }
                                          </span>
                                        </div>
                                        <div className="col col-md-3 col-sm-6">
                                          <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            className="betinputfield"
                                            id="stakevalueforselction3"
                                          />
                                        </div>
                                        <div className="col col-md-2 col-sm-6">
                                          <button
                                            className="placebetbtn"
                                            onClick={() => {
                                              this.placeBet(
                                                this.state.mands[key][3].mid,
                                                this.state.mands[key][3].sid,
                                                $(
                                                  "#stakevalueforselction3"
                                                ).val(),
                                                this.state.mands[key][3].selection_odds
                                              );
                                              this.hideorshowbetpanel(
                                                "closeall"
                                              );
                                            }}
                                          >
                                            Place Bet
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row stakes">
                                        {this.state.stakes.map((stake) => {
                                          return (
                                            <div
                                              className="col col-md-1 col-sm-4"
                                              key={stake[0]}
                                            >
                                              <button
                                                name="stack_value"
                                                className="stakeinput"
                                                onClick={() =>
                                                  this.changestakevalueAndarBahar(
                                                    stake[1],
                                                    3
                                                  )
                                                }
                                              >
                                                {stake[1]}
                                              </button>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    {/* <!-- bet panels --> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="card"
                            key={this.state.mands[key][0].mid}
                          >
                            <div
                              className="card-header"
                              id={"heading" + this.state.mands[key][0].mid}
                            >
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left marketbtn"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target={
                                    "#collapse" + this.state.mands[key][0].mid
                                  }
                                  aria-expanded="true"
                                  aria-controls={
                                    "#collapse" + this.state.mands[key][0].mid
                                  }
                                >
                                  {key}
                                </button>
                              </h2>
                            </div>
                            <div
                              id={"collapse" + this.state.mands[key][0].mid}
                              className="collapse show"
                              aria-labelledby={
                                "heading" + this.state.mands[key][0].mid
                              }
                              data-parent="#marketaccordion"
                            >
                              <div className="card-body">
                                {this.state.mands[key].map((selection) => {
                                  return (
                                    <div
                                      className="marketrow"
                                      key={selection.sid}
                                    >
                                      <div className="row">
                                        <div className="col col-md-7">
                                          {selection.selectionimg ? (
                                            <img
                                              src={
                                                this.state.basePath +
                                                selection.selectionimg
                                              }
                                              alt=""
                                              className="selectionimg"
                                              height="80px"
                                              width="auto"
                                            />
                                          ) : (
                                            <b>{selection.selectionname}</b>
                                          )}

                                          <p
                                            className="profitorloss"
                                            id={"p" + selection.sid}
                                          >
                                            -- 0.00
                                          </p>
                                        </div>
                                        <div className="col col-md-5">
                                          {this.state.timer <= 70 &&
                                          this.state.timer > 40 ? (
                                            <div
                                              className="betbox"
                                              onClick={() =>
                                                this.hideorshowbetpanel(
                                                  "m" + selection.sid
                                                )
                                              }
                                            >
                                              <span className="intotimes">
                                                {selection.selection_odds}{" "}
                                                <br /> 1M
                                              </span>
                                            </div>
                                          ) : (
                                            <div
                                              className="betbox"
                                              style={{
                                                backgroundColor: "#777",
                                                border: "none",
                                              }}
                                            >
                                              <span className="intotimes">
                                                {selection.selection_odds}{" "}
                                                <br /> 1M
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        className="optionsforbetplacing"
                                        id={"m" + selection.sid}
                                      >
                                        <div className="row">
                                          <div className="col col-md-2 col-sm-6">
                                            <button
                                              className="cancelbtn"
                                              onClick={() =>
                                                this.hideorshowbetpanel(
                                                  "m" + selection.sid
                                                )
                                              }
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                          <div className="col col-md-2 col-sm-6">
                                            <span className="intotimesdown">
                                              {selection.selection_odds}
                                            </span>
                                          </div>
                                          <div className="col col-md-4 col-sm-6">
                                            <input
                                              type="number"
                                              min="0"
                                              step="1"
                                              className="betinputfield"
                                              id={
                                                "stakevalueforselction" +
                                                selection.sid
                                              }
                                              // onChange={(e) =>
                                              //   this.betInputFieldChange(
                                              //     e,
                                              //     selection.sid,
                                              //     selection.selection_odds
                                              //   )
                                              // }
                                            />
                                          </div>
                                          <div className="col col-md-3 col-sm-6">
                                            <button
                                              className="placebetbtn"
                                              onClick={() => {
                                                this.placeBet(
                                                  this.state.mands[key][0].mid,
                                                  selection.sid,
                                                  $(
                                                    "#stakevalueforselction" +
                                                      selection.sid
                                                  ).val(),
                                                  selection.selection_odds
                                                );
                                                this.hideorshowbetpanel(
                                                  "m" + selection.sid
                                                );
                                              }}
                                            >
                                              Place Bet
                                            </button>
                                          </div>
                                        </div>
                                        <div className="row stakes">
                                          {this.state.stakes.map((stake) => {
                                            return (
                                              <div
                                                className="col col-md-1 col-sm-4"
                                                key={stake[0]}
                                              >
                                                <button
                                                  name="stack_value"
                                                  className="stakeinput"
                                                  onClick={() =>
                                                    this.changestakevalue(
                                                      stake[1],
                                                      selection.sid,
                                                      selection.selection_odds
                                                    )
                                                  }
                                                >
                                                  {stake[1]}
                                                </button>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  : ""}
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col col-md-2 col-sm-12"></div>
            <div
              className="col col-md-8 col-12"
              style={{
                backgroundColor: "#fff",
                padding: "100px 15px",
                marginTop: "5px",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              {this.state.roundMessage}
            </div>
          </div>
        )}

        <Modal open={this.state.ruleModal} onClose={this.closeRuleModal}>
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                onClick={this.closeRuleModal}
                style={{ textAlign: "right" }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div
                className="row modalcontent1"
                style={{ overflowY: "hidden" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <h3>Rules</h3>
                  <hr />
                  <div
                    style={{
                      textAlign: "left",
                      height: "350px",
                      overflowY: "auto",
                    }}
                  >
                    <div>{parse(`${this.state.rules}`)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

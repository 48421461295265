import React,{useState, useEffect} from "react";
import SidebarLeft from "./SidebarLeft";
import SidebarRight from "./SidebarRight";
import Navbar from "./Navbar";
import "./Home.css";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { $ } from "react-jquery-plugin";
import { base_url } from "../constants";

const BetHistory = (props) => {
  const { gameList, basicInfo, setLoginValue } = props;
  const [rowData, setRowData] = useState([]);
  const [totalPL, setTotalPL] = useState();
  const [message, setMessage] = useState("");
  
  useEffect(()=>{
    setMessage("Select date to get Bet History.")

    const obj = {userid: JSON.parse(localStorage.getItem("token")).uid}
    const requestOptions = {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
      body: JSON.stringify(obj),
    };

    fetch(base_url+"/welcome/totalprofitandloss", requestOptions)
    .then((res)=>res.json())
    .then((json)=>{
      setTotalPL(json.data);
    })
    .catch((err)=>console.log(err))
  
  
  },[]) 

  const handleButtonClick=(e)=>{
    let obj={}
    if(e.target.value==="today"){
      obj= {userid: JSON.parse(localStorage.getItem("token")).uid ,
            from: "",
            to: "",
            today: true}
    }else if($("input[name=fromdate]").val()==="" || $("input[name=todate]").val()===""){
      setMessage("Both from and to date should be selected.")
    }else{
      obj = {userid: JSON.parse(localStorage.getItem("token")).uid,
            from: $("input[name=fromdate]").val().replaceAll("/","-"),
            to: $("input[name=todate]").val().replaceAll("/","-"),
            today: false}
    }

    if(obj.size !== 0){
      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };

      fetch(base_url+"/welcome/getplbhistory", requestOptions)
      .then((res)=>res.json())
      .then((json)=>{
        setMessage("");
        setRowData(json.data);
        
      })
      .catch((err)=>console.log(err))
    }
  }

  return (
    <div className="home">
      <Navbar gameList={gameList} setLoginValue={setLoginValue} />
      <div className="row mainbody">
        <SidebarLeft gameList={gameList} />
        <div className="col-md-6 col-sm-12 midbar">
          <form action="" method="">
            <div className="row filterform">
              <div className="col-md-4 col-sm-12">
                <input className="form-control" name="fromdate" type="date" />
              </div>
              <div className="col-md-4 col-sm-12">
                <input className="form-control" name="todate" type="date" />
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="totalpl">Total P/L : ₹ {totalPL}</div>
              </div>
            </div>
            <div className="row filterforml2">
              <div className="col col-md-4 col-sm-6 col-xs-6">
                <button
                  type="button"
                  className="form-control btn btn-primary formbtn"
                  value="today"
                  onClick={handleButtonClick}
                >
                  Get For Today
                </button>
              </div>
              <div className="col col-md-4 col-sm-6 col-xs-6">
                <button
                  type="button"
                  className="form-control btn btn-primary formbtn"
                  onClick={handleButtonClick}
                >
                  Get Data
                </button>
              </div>
            </div>
          </form>
          {!message?"":<div style={{color:"red", fontWeight:"700"}}>{message}</div>}
          <div className="card card-accent-primary">
            <div className="card-header account-detail-head">Profit Loss & Bet History</div>
            <div className="card-body account-detail-body">
              <div>
                <div
                  className="ag-theme-alpine"
                  style={{ height: 400, width: "100%" }}
                >
                  <AgGridReact
                    rowData={rowData}
                    pagination={true}
                    paginationPageSize={10}
                  >
                    <AgGridColumn
                      headerName="Bet ID"
                      sortable={true}
                      filter={true}
                      valueGetter={(params) => {
                        return `${params.data.bid}`;
                      }}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Event Name"
                      sortable={true}
                      filter={true}
                      valueGetter={(params) => {
                        return `${params.data.gamename}`;
                      }}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Market"
                      sortable={true}
                      filter={true}
                      valueGetter={(params) => {
                        return `${params.data.marketname}`;
                      }}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="selection"
                      sortable={true}
                      filter={true}
                      valueGetter={(params) => {
                        return `${params.data.selectionname}`;
                      }}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="result"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Type"
                      sortable={true}
                      filter={true}
                      valueGetter={(params) => {
                        return `${params.data.type}`?`${params.data.type}`:"Back";
                      }}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Odds Req"
                      sortable={true}
                      filter={true}
                      valueGetter={(params) => {
                        return `${params.data.oddreq}`;
                      }}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="stake"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Profit/Loss"
                      sortable={true}
                      cellStyle = {(params) => 
                        parseInt(`${params.data.actual_profit_or_loss}`)<0? 
                        {color:"red"} : {color:"green"}
                      }
                      filter={true}
                      valueGetter={(params) => {
                        return `${params.data.actual_profit_or_loss}`;
                      }}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Settle Status"
                      sortable={true}
                      filter={true}
                      valueGetter={(params) => {
                        return `${params.data.result}`?"Settled":"Unsettled";
                      }}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Settle Time"
                      sortable={true}
                      filter={true}
                      valueGetter={(params) => {
                        return `${params.data.settletime}`;
                      }}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SidebarRight basicInfo={basicInfo} />
      </div>
    </div>
  );
};

export default BetHistory;

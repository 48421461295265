import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./Components/Home";
import Account from "./Components/Account";
import Profile from "./Components/Profile";
import BetHistory from "./Components/BetHistory";
import Games from "./Components/Games";
import Rooms from "./Components/Rooms";
import Lucky7 from "./Components/Games/Lucky7";
import {base_url} from "./constants"
import AndarBahar from "./Components/Games/AndarBahar";
import TeenPatti from "./Components/Games/TeenPatti";
import DragonTiger from "./Components/Games/DragonTiger";
import CardRace from "./Components/Games/CardRace";
import Cards32 from "./Components/Games/Cards32";

const App = () => {
  const [login, setLoginValue] = useState(false);

  const loginData = login || localStorage.getItem("token");

  const [gameList, setGameList] = useState([]);
  const [basicInfo, setBasicInfo] = useState([]);

  useEffect(() => {
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
      },
    };

    fetch(base_url + "/welcome/gameList", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setGameList(json);
      })
      .catch((err) => console.log(err));
    fetch(base_url + "/welcome/basicInformation", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setBasicInfo(json.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {}, []);
  // const route = [
  //   <ProtectedRoute
  //     path="/account"
  //     loggedIn={localStorage.getItem("token")}
  //     component={Account}
  //     gameList={gameList}
  //     basicInfo={basicInfo}
  //   />,
  //   <ProtectedRoute
  //     path="/profile"
  //     loggedIn={localStorage.getItem("token")}
  //     component={Profile}
  //     gameList={gameList}
  //     basicInfo={basicInfo}
  //   />,
  //   <ProtectedRoute
  //     path="/betHistory"
  //     loggedIn={localStorage.getItem("token")}
  //     component={BetHistory}
  //     gameList={gameList}
  //     basicInfo={basicInfo}
  //   />,
  // ];

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Home
                gameList={gameList}
                basicInfo={basicInfo}
                setLoginValue={setLoginValue}
              />
            )}
          />
          <ProtectedRoute
            path="/account"
            loggedIn={loginData}
            component={Account}
            gameList={gameList}
            basicInfo={basicInfo}
            setLoginValue={setLoginValue}
          />
          <ProtectedRoute
            path="/profile"
            loggedIn={loginData}
            component={Profile}
            gameList={gameList}
            basicInfo={basicInfo}
            setLoginValue={setLoginValue}
          />
          <ProtectedRoute
            path="/betHistory"
            loggedIn={loginData}
            component={BetHistory}
            gameList={gameList}
            basicInfo={basicInfo}
            setLoginValue={setLoginValue}
          />

          {loginData?<Route
            exact path="/games/module/1634379283"
            component={Lucky7}/>:<Redirect to="/"/>}

          {loginData?<Route
            exact path="/games/module/1635510301"
            component={AndarBahar}/>:<Redirect to="/"/>}

          {loginData?<Route
                      exact path="/games/module/1637400238"
                      component={TeenPatti}/>:<Redirect to="/"/>}  

          {loginData?<Route
                      exact path="/games/module/1639063527"
                      component={DragonTiger}/>:<Redirect to="/"/>}
          {loginData?<Route
                      exact path="/games/module/1639807499"
                      component={CardRace}/>:<Redirect to="/"/>}

          {loginData?<Route
                      exact path="/games/module/1639912330"
                      component={Cards32}/>:<Redirect to="/"/>}    

          <ProtectedRoute
            path="/games/:gameId"
            loggedIn={loginData}
            component={Games}
          />

          <ProtectedRoute
            path="/rooms/:roomId"
            loggedIn={loginData}
            component={Rooms}
          />


          {/* {localStorage.getItem("token") ? route : []} */}
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </>
  );
};

const ProtectedRoute = ({ component: Comp, loggedIn, path, ...rest }) => {
  // return <Comp {...rest} />;

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return loggedIn ? (
          <Comp {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        );
      }}
    />
  );
};

export default App;

import React, { useState } from "react";
import Navbar from "./Navbar";
import SidebarLeft from "./SidebarLeft";
import SidebarRight from "./SidebarRight";
import "./Home.css";
import Modal from "@material-ui/core/Modal";
import {base_url} from "../constants"

const Profile = (props) => {
  const { gameList, basicInfo, setLoginValue } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [newPass, setNewPass] = useState();
  const [oldPass, setOldPass] = useState();
  const [confirmPass, setConfirmPass] = useState();
  const [userData, setUserData] = useState("");
  const [error, setError] = useState("");

  if (localStorage.getItem("token") && userData.length === 0) {
    setUserData(JSON.parse(localStorage.getItem("token")));
  }

  const handleChange = (e) => {
    if (e.target.name === "oldPassword") {
      setOldPass(e.target.value);
    }
    if (e.target.name === "newPassword") {
      setNewPass(e.target.value);
    }
    if (e.target.name === "confirmPass") {
      setConfirmPass(e.target.value);
    }
  };

  const formValidate = () => {
    if (oldPass === "" || newPass === "" || confirmPass === "") {
      setError("All fields are required.");
      return false;
    } else if (confirmPass !== newPass) {
      setError("New password and confirm passwords are not same.");
      return false;
    }
    return true;
  };

  const changePassword = () => {
    if (formValidate()) {
      let obj = {
        uid: JSON.parse(localStorage.getItem("token")).uid,
        old_pass: oldPass,
        new_pass: newPass,
        confirm_pass: confirmPass,
      };
      const requestOptions = {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "secret-token": "esfbsfdgfvfghfbcvfbvdfbfzd",
        },
        body: JSON.stringify(obj),
      };

      fetch(base_url + "/welcome/changePasword", requestOptions)
        .then((res) => res.json())
        .then((json) => {
          if (json.status === 200) {
            alert(json.message);
            setError("");
            setNewPass("");
            setConfirmPass("");
          } else {
            setError(json.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  return (
    <div className="home">
      <Navbar gameList={gameList} setLoginValue={setLoginValue} />
      <div className="row mainbody">
        <SidebarLeft gameList={gameList} />
        <div className="col-md-6 col-sm-12 midbar">
          <div className="card card-accent-primary">
            <div className="card-header account-detail-head">
              Account Details
            </div>
            <div className="card-body account-detail-body">
              <div className="bd-example">
                <dl className="row">
                  <dt className="col-sm-3">Name</dt>
                  <dd className="col-sm-9"> {userData.name} </dd>
                  <dt className="col-sm-3">Exposure Limit</dt>
                  <dd className="col-sm-9"> {userData.exposurelimit} </dd>
                  <dt className="col-sm-3">Mobile Number</dt>
                  <dd className="col-sm-9"> {userData.contact} </dd>
                  <dt className="col-sm-3">Currency</dt>
                  <dd className="col-sm-9"> {userData.currency} </dd>
                  <dt className="col-sm-3">Password</dt>
                  <dd className="col-sm-9">
                    <span className="mr-3">*********</span>
                    <h6 className="edit-password" onClick={handleOpenEditModal}>
                      Edit <i className="fa fa-pencil"></i>
                    </h6>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <SidebarRight basicInfo={basicInfo} />
      </div>

      <Modal open={openEditModal} onClose={handleCloseEditModal}>
        <div className="modal-dialog">
          <div className="modal-content" style={{ height: "auto" }}>
            <div className="modal-header account-detail-head">
              <h5 className="modal-title">Change Password</h5>
              <button className="close" onClick={handleCloseEditModal}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form novalidate="" className="ng-untouched ng-pristine ng-invalid">
              <div
                className="modal-body row"
                style={{
                  backgroundColor: "#fff",
                  backgroundImage: "none",
                  padding: "1rem",
                }}
              >
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="meta_keywords">
                      Old Password<span className="asterisk_input"> *</span>
                    </label>
                    <input
                      className="form-control ng-untouched ng-pristine ng-invalid"
                      name="oldPassword"
                      placeholder="Old Password.."
                      required=""
                      type="password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="meta_keywords">
                      New Password<span className="asterisk_input"> *</span>
                    </label>
                    <input
                      className="form-control ng-untouched ng-pristine ng-invalid"
                      name="newPassword"
                      pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&amp;]{8,}$"
                      placeholder="New Password.."
                      required=""
                      type="password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="meta_keywords">
                      Confirm Password
                      <span className="asterisk_input"> *</span>
                    </label>
                    <input
                      className="form-control ng-untouched ng-pristine ng-invalid"
                      name="confirmPass"
                      placeholder="Confirm Password.."
                      required=""
                      type="password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error ? (
                  <div style={{ color: "red" }}>{error}</div>
                ) : (
                  <div style={{ display: "none" }}></div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary chngpassbtn"
                  onClick={changePassword}
                >
                  Yes
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  No
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
